import React from 'react'
import PropTypes from 'prop-types'
// Chakra Imports
import {
  Avatar,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react'
import {SidebarResponsive} from '../sidebar/Sidebar'
import {routes} from '../../variables/routes'
import {useRailsContext} from '../../contexts/RailsContext'
import {VscAccount} from 'react-icons/vsc'
// Custom Components

const HeaderLinks = ({secondary}) => {
  const {organisation, authenticityToken, plan} = useRailsContext()

  // Chakra Color Mode
  let menuBg = useColorModeValue('white', 'navy.800')
  const textColor = useColorModeValue('secondaryGray.900', 'white')

  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)')
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  )
  const backgroundColor = useColorModeValue('brand.500', 'white')

  return (
    <Flex
      w={{sm: '100%', md: 'auto'}}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? {base: 'wrap', md: 'nowrap'} : 'unset'}
      p="10px"
      borderRadius="999px"
      boxShadow={shadow}
    >
      <SidebarResponsive routes={routes(plan)} />
      <Menu>
        <MenuButton p="0px" data-testid="avatar-button">
          <Avatar
            _hover={{cursor: 'pointer'}}
            color="white"
            icon={<Icon as={VscAccount} fontSize="1.5rem" />}
            bg={backgroundColor}
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋&nbsp; {`Hey, ${organisation.name}`}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            <MenuItem
              _hover={{bg: 'none'}}
              _focus={{bg: 'none'}}
              borderRadius="8px"
              px="14px"
              as="a"
              href="/organisation_profile"
            >
              Profile
            </MenuItem>
            <form method="POST" action="/organisation_logins/sign_out">
              <input
                type="hidden"
                name="authenticity_token"
                value={authenticityToken}
              />
              <input type="hidden" name="_method" value="delete" />
              <MenuItem
                _hover={{bg: 'none'}}
                _focus={{bg: 'none'}}
                color="red.400"
                borderRadius="8px"
                px="14px"
                type="submit"
                data-testid="logout-button"
              >
                Log out
              </MenuItem>
            </form>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  )
}

HeaderLinks.defaultProps = {
  secondary: false,
}
HeaderLinks.propTypes = {
  secondary: PropTypes.bool,
}

export default HeaderLinks

import {IconButton, Tooltip as ChakraTooltip} from '@chakra-ui/react'
import React, {useCallback, useState} from 'react'
import PropTypes from 'prop-types'

const Tooltip = ({label, onClick, disabled, icon, ...props}) => {
  const [isOpen, setOpen] = useState(false)

  const hideLabel = useCallback(() => setOpen(false))
  const showLabel = useCallback(() => setOpen(true))

  return (
    <ChakraTooltip label={label} isOpen={isOpen}>
      <IconButton
        onClick={onClick}
        onTouchStart={showLabel}
        onTouchEnd={hideLabel}
        isDisabled={disabled}
        icon={icon}
        {...props}
      />
    </ChakraTooltip>
  )
}

Tooltip.defaultProps = {
  disabled: false,
  label: '',
  onClick: () => {},
}

Tooltip.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.node.isRequired,
}

export default Tooltip

import React, {useCallback} from 'react'

import PropTypes from 'prop-types'

// Custom components
import LineChartReport from '../../../components/reports/LineChartReport'
import {chartOptions} from '../../../variables/charts'

const TotalEnrollments = ({name}) => {
  const filterTotalEnrollments = useCallback(
    (data) => data?.map((entry) => entry.customer_count),
    [],
  )

  const filterTotalEnrollmentsOptions = useCallback(
    (data) =>
      chartOptions(data?.map((entry) => `${entry.month}/${entry.year}`)),
    [],
  )

  return (
    <LineChartReport
      name={name}
      linesConfig={[{name: name, filterData: filterTotalEnrollments}]}
      chartOptions={filterTotalEnrollmentsOptions}
    />
  )
}

TotalEnrollments.propTypes = {
  name: PropTypes.string.isRequired,
}

export default TotalEnrollments

import React, {useCallback, useEffect, useMemo, useState} from 'react'

import PropTypes from 'prop-types'

// Custom components
import {chartData} from '../../variables/charts'
import LineChart from '../charts/LineChart'
import ReportLayout from './ReportLayout'

const LineChartReport = ({
  name,
  title,
  chartOptions,
  linesConfig = [],
  dataCallback,
  ...props
}) => {
  let defaultLineReportChartData = [
    {
      name: title || name,
      data: [],
    },
  ]

  const [data, setData] = useState([])

  const handleData = useCallback((data) => setData(data))

  useEffect(() => {
    dataCallback && dataCallback(data)
  }, [data, dataCallback])

  const lineReportChartData = useMemo(() => {
    if (!!data && data?.length > 0) {
      let linesData = []
      linesConfig.forEach((line) => {
        linesData = [
          ...linesData,
          ...chartData(line.name, line.filterData(data)),
        ]
      })
      return linesData
    } else return defaultLineReportChartData
  }, [data, linesConfig])

  const lineReportChartOptions = useMemo(
    () => (!!data && data?.length > 0 ? chartOptions(data) : {}),
    [data, chartOptions],
  )

  return (
    <ReportLayout
      name={name}
      title={title}
      handleData={handleData}
      selectedCountries={linesConfig?.map((line) => line.name)}
      selectedItemsDescription={linesConfig?.map((line) => line.name)}
      {...props}
    >
      <LineChart
        chartData={lineReportChartData}
        chartOptions={lineReportChartOptions}
      />
    </ReportLayout>
  )
}

LineChartReport.defaultProps = {
  title: undefined,
  dataCallback: () => {},
}

LineChartReport.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  chartOptions: PropTypes.func.isRequired,
  linesConfig: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      filterData: PropTypes.func,
    }),
  ).isRequired,
  dataCallback: PropTypes.func,
}

export default LineChartReport

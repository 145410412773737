import React from 'react'
import PropTypes from 'prop-types'

// Custom components
import TableReport from '../../../components/reports/TableReport'

const CustomerChangeLog = ({name}) => {
  const CustomerChangeLogDataColumns = [
    {
      header: 'Customer Id',
      accessor: 'customer_id',
    },
    {
      header: 'Modified Date',
      accessor: 'modified_date',
      is_date: true,
    },
    {
      header: 'Modified By',
      accessor: 'modified_by',
    },
    {
      header: 'Detail',
      accessor: 'detail',
    },
  ]

  return <TableReport name={name} dataColumns={CustomerChangeLogDataColumns} />
}

CustomerChangeLog.propTypes = {
  name: PropTypes.string.isRequired,
}

export default CustomerChangeLog

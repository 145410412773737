import React from 'react'

import {withRails} from '../../contexts/RailsContext'
import withSWR from '../../contexts/SwrContext'

// Chakra imports
import {Flex, Grid, useColorModeValue} from '@chakra-ui/react'

// Custom components
import AdminLayout from '../../layouts/Admin'
import {VSeparator} from '../../components/separator/Separator'
import QuantityPerformance from './components/QuantityPerformance'
import ValuePerformance from './components/ValuePerformance'
import AverageOrderValue from './components/AverageOrderValue'
import PurchasesPerCustomerRollingYear from './components/PurchasesPerCustomerRollingYear'
import RecentProductPurchases from './components/RecentProductPurchases'
import Report from '../../components/reports/Report'

export const OrdersScreen = () => {
  // Chakra Color Mode
  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100')

  return (
    <AdminLayout>
      <Flex
        direction={{base: 'column', xl: 'row'}}
        pt={{base: '130px', md: '80px', xl: '80px'}}
      >
        <Flex direction="column" width="stretch">
          <Report
            name={'Quantity Performance'}
            component={QuantityPerformance}
          />
          <Report name={'Value Performance'} component={ValuePerformance} />

          <Grid
            templateColumns={{
              base: 'repeat(auto-fit, minmax(250px, 1fr))',
            }}
            gap="20px"
            display={{base: 'block', lg: 'grid'}}
          >
            <Report
              name={'Average Order Value'}
              component={AverageOrderValue}
            />
            <Report
              name={'Purchases Per Customer Rolling Year'}
              component={PurchasesPerCustomerRollingYear}
            />
          </Grid>

          <Report
            name={'Recent Product Purchases'}
            component={RecentProductPurchases}
          />
        </Flex>
        <VSeparator
          mx="20px"
          bg={paleGray}
          display={{base: 'none', xl: 'flex'}}
        />
      </Flex>
    </AdminLayout>
  )
}

OrdersScreen.propTypes = {}

export default withRails(withSWR(OrdersScreen))

import React from 'react'
import {countries} from 'countries-list'
import {Flex, Grid, Select, Stack, Button} from '@chakra-ui/react'
import {useKpiContext} from '../../../contexts/KpiContext'

const Filters = () => {
  const {setTimePeriod, country, setCountry} = useKpiContext()

  const handleChange = (event) => {
    setTimePeriod(event.target.value)
  }

  const handleSelect = (event) => {
    setCountry(event.target.value)
  }

  const countryOptions = [
    {value: 'ALL', label: 'All Countries'},
    ...Object.keys(countries).map((countryCode) => ({
      value: countryCode,
      label: countries[countryCode].name,
    })),
  ]

  return (
    <Grid
      mb="20px"
      gridTemplateColumns={{
        base: 'repeat(2, 1fr)',
        '2xl': '350fr 350fr',
      }}
      gap="20px"
      display={{base: 'block', lg: 'grid'}}
    >
      <Flex gridArea={{base: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 3'}}>
        <Stack direction="row" spacing={0} mb="20px" w={'100%'}>
          <Button
            onClick={handleChange}
            value={'day'}
            isLoading={false}
            colorScheme="blue"
            style={{borderRadius: '16px 0 0 16px'}}
          >
            Day
          </Button>
          <Button
            onClick={handleChange}
            data-testid="weekTimePeriod"
            value={'week'}
            isLoading={false}
            colorScheme="blue"
            variant="solid"
            style={{borderRadius: '0'}}
          >
            Week
          </Button>
          <Button
            onClick={handleChange}
            value={'month'}
            isLoading={false}
            colorScheme="blue"
            variant="solid"
            style={{borderRadius: '0'}}
          >
            Month
          </Button>
          <Button
            onClick={handleChange}
            value={'quarterly'}
            isLoading={false}
            colorScheme="blue"
            variant="solid"
            style={{borderRadius: '0'}}
          >
            Quarter
          </Button>
          <Button
            onClick={handleChange}
            value={'year'}
            isLoading={false}
            colorScheme="blue"
            style={{borderRadius: '0 16px 16px 0'}}
          >
            Year
          </Button>
        </Stack>
      </Flex>
      <Flex gridArea={{base: '2 / 1 / 2 / 3', '2xl': '2 / 1 / 2/ 1'}}>
        <Select
          data-testid="selectCountry"
          placeholder="Select option"
          onChange={handleSelect}
          value={country}
        >
          {countryOptions.map((option, index) => (
            <option value={option.value} key={index}>
              {option.label}
            </option>
          ))}
        </Select>
      </Flex>
    </Grid>
  )
}

export default Filters

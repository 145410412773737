import React from 'react'
import PropTypes from 'prop-types'

// Chakra imports
import {Box, Flex, Text} from '@chakra-ui/react'

// Custom components
import Card from '../../components/card/Card'
import Footer from '../../components/footer/FooterAuthCentered'

import ThemeProvider from '../../components/ThemeProvider'

function AuthCentered(props) {
  const {children, cardTop, cardBottom} = props
  return (
    <ThemeProvider>
      <Flex
        direction="column"
        alignSelf="center"
        justifySelf="center"
        overflow="hidden"
        mx={{base: '10px', lg: '0px'}}
        minH="100vh"
      >
        <Box
          position="absolute"
          minH={{base: '50vh', md: '50vh'}}
          maxH={{base: '50vh', md: '50vh'}}
          w={{md: 'calc(100vw)'}}
          maxW={{md: 'calc(100vw)'}}
          left="0"
          right="0"
          bgRepeat="no-repeat"
          overflow="hidden"
          top="0"
          bgGradient="linear(to-b, brand.400, brand.600)"
          mx={{md: 'auto'}}
        />
        {/* <Navbar /> */}
        <Card
          w={{base: '100%', md: 'max-content'}}
          h="max-content"
          mx="auto"
          maxW="100%"
          mt={cardTop}
          mb={cardBottom}
          p={{base: '10px', md: '50px'}}
          pt={{base: '30px', md: '50px'}}
        >
          {children}
        </Card>
        <Footer />
      </Flex>
    </ThemeProvider>
  )
}

AuthCentered.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthCentered

import React from 'react'
import PropTypes from 'prop-types'

// Custom components
import KpiReport from '../../../components/reports/KpiReport'

const EnrollmentsByTimePeriod = ({name}) => {
  const titlePrefix = 'BP Enrollments By'

  return (
    <KpiReport name={name} titlePrefix={titlePrefix} subtitle="Performance" />
  )
}

EnrollmentsByTimePeriod.propTypes = {
  name: PropTypes.string.isRequired,
}

export default EnrollmentsByTimePeriod

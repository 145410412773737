import React from 'react'

import {withRails} from '../../contexts/RailsContext'
import withSWR from '../../contexts/SwrContext'

// Chakra imports
import {Flex, Grid, useColorModeValue} from '@chakra-ui/react'

// Custom components
import AdminLayout from '../../layouts/Admin'
import {VSeparator} from '../../components/separator/Separator'
import SalesVsCommissions from './components/SalesVsCommissions'
import PayeesByMonth from './components/PayeesByMonth'
import UsersEarningBonuses from './components/UsersEarningBonuses'
import AverageRankAdvancementTime from './components/AverageRankAdvancementTime'
import RankReports from './components/Ranks'
import AmountPaidByBonus from './components/AmountPaidByBonus'
import Bonuses from './components/Bonuses'
import Report from '../../components/reports/Report'

export const CommissionsScreen = () => {
  // Chakra Color Mode
  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100')

  return (
    <AdminLayout>
      <Flex
        direction={{base: 'column', xl: 'row'}}
        pt={{base: '130px', md: '80px', xl: '80px'}}
      >
        <Flex direction="column" width="stretch">
          <Report
            name={'Sales Vs Commissions'}
            component={SalesVsCommissions}
          />
          <Grid
            gap="20px"
            templateColumns={{md: 'repeat(auto-fit, minmax(250px, 1fr))'}}
          >
            <Report name={'Payees by Month'} component={PayeesByMonth} />
            <Report
              name={'Users Earning Bonuses'}
              component={UsersEarningBonuses}
            />
            <Report
              name={'Amount Paid by Bonus'}
              component={AmountPaidByBonus}
            />
          </Grid>
          <Report
            name={'Average Rank Advancement Time'}
            component={AverageRankAdvancementTime}
          />
          <Grid
            templateColumns={{base: 'repeat(auto-fit, minmax(250px, 1fr))'}}
            gap="20px"
            display={{base: 'block', lg: 'grid'}}
          >
            <Report name={'Ranks'} component={RankReports} />
            <Report name={'Bonuses'} component={Bonuses} />
          </Grid>
        </Flex>
        <VSeparator
          mx="20px"
          bg={paleGray}
          display={{base: 'none', xl: 'flex'}}
        />
      </Flex>
    </AdminLayout>
  )
}

CommissionsScreen.propTypes = {}

export default withRails(withSWR(CommissionsScreen))

import React, {useCallback} from 'react'
import PropTypes from 'prop-types'

// Custom components
import BarChartReport from '../../../components/reports/BarChartReport'

const ValuePerformance = ({name}) => {
  const valuePerformanceChartData = useCallback(
    (data) => data?.map((entry) => entry.total),
    [],
  )

  const valuePerformanceChartOptions = useCallback(
    (data) => data?.map((entry) => `${entry.item_description}`),
    [],
  )

  return (
    <BarChartReport
      name={name}
      subtitle="Dollar Value Of Product Sold Last 30 Days"
      filterData={valuePerformanceChartData}
      filterOptions={valuePerformanceChartOptions}
      showLabels={false}
    />
  )
}

ValuePerformance.propTypes = {
  name: PropTypes.string.isRequired,
}

export default ValuePerformance

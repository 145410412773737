const lineChartColors = Array.from({length: 3}, () => [
  'var(--chakra-colors-brand-500)',
  'var(--chakra-colors-cyan-400)',
  'var(--chakra-colors-brand-900)',
  'var(--chakra-colors-brand-700)',
  'var(--chakra-colors-yellow-300)',
]).flat()

export const lineChartDataOverallRevenue = [
  {
    name: 'Revenue',
    data: [50, 64, 48, 66, 49, 68],
  },
]

export const lineChartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
    dropShadow: {
      enabled: true,
      top: 13,
      left: 0,
      blur: 10,
      opacity: 0.1,
      color: '#4318FF',
    },
  },
  colors: lineChartColors,
  markers: {
    size: 3,
    colors: ['white'],
    strokeColors: lineChartColors,
    strokeWidth: 3,
    strokeOpacity: 0.9,
    strokeDashArray: 0,
    fillOpacity: 1,
    discrete: [],
    shape: 'circle',
    radius: 2,
    offsetX: 0,
    offsetY: 0,
    showNullDataPoints: true,
  },
  tooltip: {
    theme: 'dark',
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
    type: 'line',
  },
  yaxis: {
    show: true,
  },
  legend: {
    show: false,
  },
  grid: {
    show: false,
    column: {
      color: ['#7551FF', '#39B8FF'],
      opacity: 0.5,
    },
  },
}

export const chartData = (name, data) => {
  return [
    {
      name: name,
      data: data,
    },
  ]
}

export const chartOptions = (data) => {
  return {
    ...lineChartOptions,
    colors: lineChartColors,
    xaxis: {
      type: 'numeric',
      categories: data,
      labels: {
        style: {
          colors: '#A3AED0',
          fontSize: '12px',
          fontWeight: '500',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
  }
}

export const barChartDefaultOptions = {
  chart: {
    stacked: true,
    toolbar: {
      show: false,
    },
  },
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
  },
  tooltip: {
    style: {
      fontSize: '12px',
      fontFamily: undefined,
    },
    onDatasetHover: {
      style: {
        fontSize: '12px',
        fontFamily: undefined,
      },
    },
    theme: 'dark',
  },
  yaxis: {
    show: true,
    color: 'black',
    labels: {
      show: true,
      style: {
        colors: '#A3AED0',
        fontSize: '14px',
        fontWeight: '500',
      },
    },
  },
  grid: {
    borderColor: 'rgba(163, 174, 208, 0.3)',
    show: true,
    yaxis: {
      lines: {
        show: true,
        opacity: 0.5,
      },
    },
    row: {
      opacity: 0.5,
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  fill: {
    type: 'solid',
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
}

export const barChartOptions = (
  data,
  showLabels = true,
  columnWidth = '70%',
) => {
  return {
    ...barChartDefaultOptions,
    xaxis: {
      categories: data,
      show: false,
      labels: {
        trim: true,
        show: showLabels,
        style: {
          colors: '#A3AED0',
          fontSize: '14px',
          fontWeight: '500',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: columnWidth,
        distributed: true,
      },
    },
    colors: [
      'var(--chakra-colors-brand-50)',
      'var(--chakra-colors-brand-100)',
      'var(--chakra-colors-brand-200)',
      'var(--chakra-colors-brand-300)',
      'var(--chakra-colors-brand-400)',
      'var(--chakra-colors-brand-500)',
      'var(--chakra-colors-brand-600)',
      'var(--chakra-colors-brand-700)',
      'var(--chakra-colors-brand-800)',
      'var(--chakra-colors-brand-900)',
    ],
  }
}

import React from 'react'
import {
  Flex,
  Link,
  List,
  ListItem,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'

export default function Footer() {
  const textColor = useColorModeValue('gray.400', 'white')
  return (
    <Flex
      w={{base: '100%', xl: '1170px'}}
      maxW={{base: '90%', xl: '1170px'}}
      zIndex="1.5"
      flexDirection={{
        base: 'column',
        xl: 'row',
      }}
      alignItems={{
        base: 'center',
        xl: 'start',
      }}
      justifyContent="center"
      px={{base: '0px', xl: '0px'}}
      pb="30px"
      mx="auto"
    >
      <List display="flex">
        <ListItem
          me={{
            base: '20px',
            md: '44px',
          }}
        >
          <Link fontWeight="500" color={textColor} href="/">
            Support
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: '20px',
            md: '44px',
          }}
        >
          <Link fontWeight="500" color={textColor} href="/">
            License
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: '20px',
            md: '44px',
          }}
        >
          <Link fontWeight="500" color={textColor} href="/">
            Terms of Use
          </Link>
        </ListItem>
      </List>
    </Flex>
  )
}

import React from 'react'
import PropTypes from 'prop-types'

// Chakra imports
import {Portal, Box, useDisclosure, useColorModeValue} from '@chakra-ui/react'
import Footer from '../../components/footer/FooterAdmin'
// Layout components
import Navbar from '../../components/navbar/NavbarAdmin'
import Sidebar from '../../components/sidebar/Sidebar'
import {SidebarContext} from '../../contexts/SidebarContext'
import {useState} from 'react'
import ThemeProvider from '../../components/ThemeProvider'
import {routes} from '../../variables/routes'
import {useRailsContext} from '../../contexts/RailsContext'

// Custom Chakra theme
const AdminLayout = ({children, ...rest}) => {
  // states and functions
  const [fixed] = useState(false)
  const [toggleSidebar, setToggleSidebar] = useState(false)
  const [mini, setMini] = useState(false)
  const [hovered, setHovered] = useState(false)

  const {plan} = useRailsContext()

  const availableRoutes = routes(plan).filter((route) => !route.disable)

  document.documentElement.dir = 'ltr'
  const {onOpen} = useDisclosure()

  const bg = useColorModeValue('background.100', 'background.900')

  return (
    <ThemeProvider>
      <Box bg={bg} h="100vh" w="100vw">
        <SidebarContext.Provider
          value={{
            toggleSidebar,
            setToggleSidebar,
          }}
        >
          <Sidebar
            hovered={hovered}
            setHovered={setHovered}
            mini={mini}
            routes={availableRoutes}
            {...rest}
          />
          <Box
            float="right"
            minHeight="100vh"
            height="100%"
            overflow="auto"
            position="relative"
            maxHeight="100%"
            w={
              mini === false
                ? {base: '100%', xl: 'calc( 100% - 290px )'}
                : mini === true && hovered === true
                ? {base: '100%', xl: 'calc( 100% - 290px )'}
                : {base: '100%', xl: 'calc( 100% - 120px )'}
            }
            maxWidth={
              mini === false
                ? {base: '100%', xl: 'calc( 100% - 290px )'}
                : mini === true && hovered === true
                ? {base: '100%', xl: 'calc( 100% - 290px )'}
                : {base: '100%', xl: 'calc( 100% - 120px )'}
            }
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            transitionDuration=".2s, .2s, .35s"
            transitionProperty="top, bottom, width"
            transitionTimingFunction="linear, linear, ease"
          >
            <Portal>
              <Box>
                <Navbar
                  hovered={hovered}
                  setMini={setMini}
                  mini={mini}
                  onOpen={onOpen}
                  fixed={fixed}
                  {...rest}
                />
              </Box>
            </Portal>

            <Box
              mx="auto"
              p={{base: '20px', md: '30px'}}
              pe="20px"
              minH="100vh"
              pt="50px"
            >
              {children}
            </Box>

            <Box>
              <Footer />
            </Box>
          </Box>
        </SidebarContext.Provider>
      </Box>
    </ThemeProvider>
  )
}

AdminLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AdminLayout

import React from 'react'
import PropTypes from 'prop-types'

// Custom components
import TableReport from '../../../components/reports/TableReport'

const Aia = ({name}) => {
  const aiaDataColumns = [
    {
      header: 'Customer ID',
      accessor: 'customer_id',
    },
    {
      header: 'Name',
      accessor: 'name',
    },
    {
      header: 'Personal Volume Points',
      accessor: 'personal_volume_points',
    },
    {
      header: 'Personal Enrollment Points',
      accessor: 'personal_enrollment_points',
    },
    {
      header: 'Personal Enroll Rank Adv Points',
      accessor: 'personal_enroll_rank_adv_points',
    },
    {
      header: 'Personal Rank Adv Points',
      accessor: 'personal_rank_adv_points',
    },
    {
      header: 'Total Points Product',
      accessor: 'total_points_product',
    },
  ]
  return (
    <TableReport name={name} dataColumns={aiaDataColumns} showPeriodSelector />
  )
}

Aia.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Aia

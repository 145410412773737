import React, {useCallback} from 'react'
import PropTypes from 'prop-types'

// Custom components
import LineChartReport from '../../../components/reports/LineChartReport'
import {chartOptions} from '../../../variables/charts'
import {formatDate, oneYearAgo} from '../../../utils/formatter'

const SalesVsCommissions = ({name}) => {
  const formatedOneYearAgo = formatDate(oneYearAgo())
  const formatedToday = formatDate(new Date())

  const filterSalesData = useCallback(
    (data) => data?.map((entry) => entry.sales_total),
    [],
  )

  const filterCommissionsData = useCallback(
    (data) => data?.map((entry) => entry.commissions_total),
    [],
  )

  const linesConfig = [
    {name: 'Sales', filterData: filterSalesData},
    {name: 'Commissions', filterData: filterCommissionsData},
  ]

  const salesVsCommissionsOptions = useCallback(
    (data) =>
      chartOptions(
        data?.map((entry) => {
          const percent = (entry?.commissions_total / entry?.sales_total) * 100
          return `${entry.month}/${entry.year} - ${percent.toFixed(2)}%`
        }),
      ),
    [],
  )

  return (
    <LineChartReport
      name={name}
      subtitle="Performance"
      chartOptions={salesVsCommissionsOptions}
      linesConfig={linesConfig}
      defaultStartDate={formatedOneYearAgo}
      defaultEndDate={formatedToday}
      showReportsFilter
    />
  )
}

SalesVsCommissions.propTypes = {
  name: PropTypes.string.isRequired,
}

export default SalesVsCommissions

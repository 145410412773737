import React from 'react'
import PropTypes from 'prop-types'
import {useRailsContext} from '../../contexts/RailsContext'

// Custom components
import {renderComponent} from '../../utils/componentRendering'

const Report = ({name, component, ...props}) => {
  const {reports} = useRailsContext()
  const Component = component

  return renderComponent(name, reports) && <Component name={name} {...props} />
}

Report.propTypes = {
  name: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
}

export default Report

import React, {useCallback} from 'react'

import PropTypes from 'prop-types'

// Custom components
import LineChartReport from '../../../components/reports/LineChartReport'
import {chartOptions} from '../../../variables/charts'
import {formatDate, oneYearAgo} from '../../../utils/formatter'

const NewAutoshipsByMonth = ({name}) => {
  const formatedOneYearAgo = formatDate(oneYearAgo())
  const formatedToday = formatDate(new Date())

  const filterNewAutoshipsData = useCallback(
    (data) => data?.map((entry) => entry.total),
    [],
  )

  const newAutoshipsOptions = useCallback(
    (data) =>
      chartOptions(data?.map((entry) => `${entry.month}/${entry.year}`)),
    [],
  )

  return (
    <LineChartReport
      name={name}
      linesConfig={[{name: name, filterData: filterNewAutoshipsData}]}
      chartOptions={newAutoshipsOptions}
      defaultStartDate={formatedOneYearAgo}
      defaultEndDate={formatedToday}
      showReportsFilter
    />
  )
}

NewAutoshipsByMonth.propTypes = {
  name: PropTypes.string.isRequired,
}

export default NewAutoshipsByMonth

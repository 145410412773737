import React from 'react'
import PropTypes from 'prop-types'

// Chakra imports
import {Box, Flex, Heading, useColorModeValue, Text} from '@chakra-ui/react'

// Custom components
import CenteredAuth from '../../layouts/Auth'
import SignInForm from './components/SignInForm'

const SignInScreen = ({authenticityToken, errorMessages}) => {
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white')
  const textColorSecondary = 'gray.400'

  const serverErrors = []
  if (Array.isArray(errorMessages)) {
    serverErrors.push(...errorMessages)
  } else {
    serverErrors.push(errorMessages)
  }


  return (
    <CenteredAuth
      cardTop={{base: '140px', md: '14vh'}}
      cardBottom={{base: '50px', lg: 'auto'}}
      mx="0px"
    >
      <Flex direction="column">
        {serverErrors
          .filter((item) => item != null)
          .map((error, index) => (
            <Text
              key={index}
              fontSize="md"
              color={textColor}
              fontWeight="normal"
              mt="10px"
              mb="26px"
              w={{base: '90%', sm: '60%', lg: '40%', xl: '333px'}}
            >
              {error}
            </Text>
          ))}
      </Flex>

      <Flex
        maxW={{base: '100%', md: 'max-content'}}
        w="100%"
        mx={{base: 'auto', lg: '0px'}}
        me="auto"
        justifyContent="center"
        px={{base: '20px', md: '0px'}}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign In
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{base: '100%', md: '420px'}}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{base: 'auto', lg: 'unset'}}
          me="auto"
          mb={{base: '20px', md: 'auto'}}
        >
          <Flex align="center"></Flex>
          <SignInForm authenticityToken={authenticityToken} />
        </Flex>
      </Flex>
    </CenteredAuth>
  )
}

SignInScreen.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
}

export default SignInScreen

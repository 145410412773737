import React, {useEffect, useMemo, useState} from 'react'

import PropTypes from 'prop-types'
import {Flex, Grid, Text} from '@chakra-ui/react'
import useSWR from 'swr'
import {getCountryData, getCountryCode} from 'countries-list'
// import {sortBy} from 'lodash-es'
import sortBy from 'lodash/sortBy'

// Custom components
import Card from '../card/Card'
import DatesFilter from '../datesFilter/DatesFilter'
import ExportMenu from '../exportMenu/ExportMenu'
import MultiSelect from '../multiSelect/MultiSelect'
import Loader from '../loader/Loader'
import ErrorMessage from '../errorMessage/ErrorMessage'
import {useRailsContext} from '../../contexts/RailsContext'
import {reportBarWidth, reportsBarWidthMobile} from '../../utils/sizes'
import PeriodSelector from '../periodSelector/PeriodSelector'

const ReportLayout = ({
  name,
  title,
  subtitle,
  params: extraParams,
  handleData,
  showReportsFilter,
  defaultStartDate,
  defaultEndDate,
  showCountriesSelect,
  showItemsDescriptionSelect,
  onSelectCountriesChange,
  onSelectItemsDescriptionsChange,
  selectedCountries,
  selectedItemsDescription,
  showPeriodSelector,
  children,
}) => {
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)
  const [period, setPeriod] = useState()

  let params =
    startDate && endDate ? `?start_date=${startDate}&end_date=${endDate}` : ''
  const separator = params.length > 0 ? '&' : '?'
  params += extraParams.length > 0 ? `${separator}${extraParams}` : ''
  if (showPeriodSelector && period) {
    params += `${separator}period_id=${period.key}&period_type=${period.type}`
  }

  const {data, error, isLoading} = useSWR(`/api/reports/${name}${params}`)

  const {plan} = useRailsContext()

  useEffect(() => {
    if (!!data && data.length) {
      handleData(data)
    }
  }, [data])

  const handlePeriodChange = (newPeriod) => {
    setPeriod(newPeriod)
  }

  const countriesList = useMemo(() => {
    if (showCountriesSelect && !!data && data.length) {
      const countriesNameList = data?.reduce(
        (acc, curr) =>
          acc.find((element) => element?.key === curr?.country)
            ? acc
            : [
                ...acc,
                {key: curr.country, name: getCountryData(curr.country)?.name},
              ],
        [],
      )
      return sortBy(countriesNameList, 'name')
    }
    return []
  }, [showCountriesSelect, data])

  const itemsDescriptionsList = useMemo(() => {
    if (showItemsDescriptionSelect && !!data && data.length) {
      const itemsDescriptionList = data?.reduce(
        (acc, curr) =>
          acc.find((element) => element?.key === curr?.item_description)
            ? acc
            : [
                ...acc,
                {
                  key: curr.item_description,
                  name: curr.item_description,
                },
              ],
        [],
      )
      return sortBy(itemsDescriptionList, 'name')
    }
    return []
  }, [showItemsDescriptionSelect, data])

  const optionsWidth = useMemo(
    () =>
      reportBarWidth(
        showReportsFilter && plan?.reports_filter,
        plan?.allow_export,
      ),
    [showReportsFilter, plan],
  )

  const optionsWidthMobile = useMemo(
    () =>
      reportsBarWidthMobile(
        showReportsFilter && plan?.reports_filter,
        plan?.allow_export,
      ),
    [showReportsFilter, plan],
  )

  const exportReportParams = useMemo(
    () =>
      params +
      (showCountriesSelect
        ? `${
            params?.length > 0 ? '&' : '?'
          }filter_countries=${selectedCountries.map((country) =>
            getCountryCode(country),
          )}`
        : ''),
    [params, showCountriesSelect, selectedCountries],
  )

  const selectToShowReportMessage = useMemo(() => {
    return `Select
              ${[
                showPeriodSelector ? ' a period ' : null,
                showCountriesSelect ? ' countries ' : null,
                showItemsDescriptionSelect ? ' any Item Description ' : null,
              ]
                .filter((element) => !!element)
                .join(',')}
              to see the report`
  }, [showCountriesSelect, showPeriodSelector, showItemsDescriptionSelect])

  return (
    <Card
      justifyContent="flex-start"
      alignItems="center"
      flexDirection="column"
      w="100%"
      mb={'20px'}
    >
      <Grid
        gap="10px"
        templateColumns={{
          base: `auto ${optionsWidthMobile}px`,
          lg: `auto auto ${optionsWidth}px`,
        }}
        alignItems="center"
        justify="space-between"
        mx="20px"
        mt="5px"
        w="100%"
      >
        <Flex
          gridArea={'1/1/2/2'}
          flexDirection="column"
          justify={'flex-start'}
          alignSelf={'center'}
        >
          <Text
            color="secondaryGray.600"
            fontSize="sm"
            fontWeight="500"
            textAlign={'start'}
          >
            {title || name}
          </Text>
          {subtitle && (
            <Text
              textAlign={'start'}
              color="secondaryGray.600"
              fontSize="xl"
              fontWeight="500"
            >
              {subtitle}
            </Text>
          )}
        </Flex>

        <Flex
          gridArea={{base: '1/2/2/3', lg: '1/3/2/4'}}
          alignItems={'flex-start'}
          flexDir={'row'}
          justify={'flex-end'}
        >
          {showReportsFilter && plan?.reports_filter && (
            <DatesFilter
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              isDisabled={isLoading}
            />
          )}
          {!error && plan?.allow_export && (
            <ExportMenu
              isDisabled={isLoading}
              reportId={name}
              params={exportReportParams}
            />
          )}
        </Flex>

        {(showCountriesSelect ||
          showPeriodSelector ||
          showItemsDescriptionSelect) && (
          <Flex
            gridArea={{base: '2/1/3/3', lg: '1/2/2/3'}}
            justify={{base: 'center', lg: 'flex-end'}}
            flexDir={{base: 'column', md: 'row'}}
          >
            {showCountriesSelect && (
              <MultiSelect
                isDisabled={isLoading}
                title="Select countries"
                list={countriesList}
                onChange={onSelectCountriesChange}
                mb={{base: '3%', md: 0}}
              />
            )}
            {showItemsDescriptionSelect && (
              <MultiSelect
                isDisabled={isLoading}
                title="Select Items"
                list={itemsDescriptionsList}
                onChange={onSelectItemsDescriptionsChange}
                mb={{base: '3%', md: 0}}
              />
            )}
            {showPeriodSelector && (
              <PeriodSelector
                reportName={name}
                isDisabled={isLoading}
                handleOnChange={handlePeriodChange}
                selectedPeriod={period?.name}
              />
            )}
          </Flex>
        )}
      </Grid>
      <Loader isLoading={isLoading}>
        <ErrorMessage error={error}>
          {(showPeriodSelector && !period) ||
          (showItemsDescriptionSelect &&
            selectedItemsDescription?.length === 0) ||
          (showCountriesSelect && selectedCountries?.length === 0) ? (
            <Text
              textAlign={'center'}
              position="absolute"
              top={'50%'}
              left={'5%'}
              right={'5%'}
            >
              {selectToShowReportMessage}
            </Text>
          ) : (
            children
          )}
        </ErrorMessage>
      </Loader>
    </Card>
  )
}

ReportLayout.defaultProps = {
  title: undefined,
  subtitle: '',
  params: '',
  showReportsFilter: false,
  defaultStartDate: '',
  defaultEndDate: '',
  showCountriesSelect: false,
  showItemsDescriptionSelect: false,
  onSelectCountriesChange: () => {},
  onSelectItemsDescriptionsChange: () => {},
  selectedCountries: [],
  selectedItemsDescription: [],
  showPeriodSelector: false,
}

ReportLayout.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  params: PropTypes.string,
  handleData: PropTypes.func.isRequired,
  showReportsFilter: PropTypes.bool,
  defaultStartDate: PropTypes.string,
  defaultEndDate: PropTypes.string,
  showCountriesSelect: PropTypes.bool,
  showItemsDescriptionSelect: PropTypes.bool,
  onSelectCountriesChange: PropTypes.func,
  onSelectItemsDescriptionsChange: PropTypes.func,
  selectedCountries: PropTypes.array,
  selectedItemsDescription: PropTypes.array,
  showPeriodSelector: PropTypes.bool,
  children: PropTypes.object.isRequired,
}

export default ReportLayout

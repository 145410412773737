import React, {useCallback} from 'react'
import PropTypes from 'prop-types'

// Custom components
import BarChartReport from '../../../components/reports/BarChartReport'

const UsersEarningBonuses = ({name}) => {
  const usersEarningBonusesChartData = useCallback(
    (data) => data?.map((entry) => entry.count),
    [],
  )

  const usersEarningBonusesChartOptions = useCallback(
    (data) => data?.map((entry) => `${entry.bonus_description}`),
    [],
  )

  return (
    <BarChartReport
      name={name}
      subtitle="Rolling Year"
      filterData={usersEarningBonusesChartData}
      filterOptions={usersEarningBonusesChartOptions}
      showLabels={false}
    />
  )
}

UsersEarningBonuses.propTypes = {
  name: PropTypes.string.isRequired,
}

export default UsersEarningBonuses

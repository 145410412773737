import React from 'react'
import PropTypes from 'prop-types'
// chakra imports
import {
  Avatar,
  Box,
  Flex,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
//   Custom components
import Brand from '../components/Brand'
import Links from '../components/Links'
import {useRailsContext} from '../../../contexts/RailsContext'

// FUNCTIONS

const SidebarContent = ({routes, mini, hovered}) => {
  const {organisation} = useRailsContext()

  const textColor = useColorModeValue('navy.700', 'white')
  // SIDEBAR
  return (
    <Flex direction="column" height="100%" pt="25px" borderRadius="30px">
      <Brand mini={mini} hovered={hovered} />
      <Stack direction="column" mb="auto" mt="8px">
        <Box
          ps={
            mini === false
              ? '20px'
              : mini === true && hovered === true
                ? '20px'
                : '16px'
          }
          pe={{md: '16px', '2xl': '1px'}}
          ms={mini && hovered === false ? '-16px' : 'unset'}
        >
          <Links mini={mini} hovered={hovered} routes={routes} />
        </Box>
      </Stack>
      <Flex mt="50px" mb="66px" justifyContent="center" alignItems="center">
        <Avatar
          h="48px"
          w="48px"
          me={
            mini === false
              ? '20px'
              : mini === true && hovered === true
                ? '20px'
                : '0px'
          }
        />
        <Box
          display={
            mini === false
              ? 'block'
              : mini === true && hovered === true
                ? 'block'
                : 'none'
          }
        >
          <Text color={textColor} fontSize="md" fontWeight="700">
            {organisation.name}
          </Text>
          <Text color="secondaryGray.600" fontSize="sm" fontWeight="400">
            {organisation.email}
          </Text>
        </Box>
      </Flex>
    </Flex>
  )
}

SidebarContent.defaultProps = {
  hovered: false,
}

SidebarContent.propTypes = {
  mini: PropTypes.bool.isRequired,
  hovered: PropTypes.bool,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
      collapse: PropTypes.bool.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
}
export default SidebarContent

import React, {useMemo, Suspense} from 'react'
import PropTypes from 'prop-types'

const RailsContext = React.createContext()

// form_authenticity_token
const useAuthenticityToken = (token = '') => {
  const [authenticityToken, updateAuthenticityToken] = React.useState(token)
  return {authenticityToken, updateAuthenticityToken}
}

const RailsProvider = ({children, ...props}) => {
  const {authenticityToken: token} = props
  const {authenticityToken, updateAuthenticityToken} =
    useAuthenticityToken(token)

  const value = useMemo(
    () => ([props], [props]),
    [authenticityToken, updateAuthenticityToken],
  )

  return (
    <RailsContext.Provider value={value} {...props}>
      <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>
    </RailsContext.Provider>
  )
}

RailsProvider.defaultProps = {
  organisation: null,
  plan: {kpi: false, allow_export: false, reports_filter: false},
}

RailsProvider.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  organisation: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
  plan: PropTypes.shape({
    kpi: PropTypes.bool.isRequired,
    allow_export: PropTypes.bool,
    reports_filter: PropTypes.bool,
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

const useRailsContext = () => {
  const context = React.useContext(RailsContext)

  if (!context) {
    throw new Error('useRailsProvider must be used within a RailsProvider')
  }

  const [data] = context
  const {
    authenticityToken,
    updateAuthenticityToken,
    organisation,
    plan,
    reports,
    permissionMessages,
    pagePermissions,
    role,
  } = data

  return {
    authenticityToken,
    updateAuthenticityToken,
    organisation,
    plan,
    reports,
    permissionMessages,
    pagePermissions,
    role,
  }
}

const getDisplayName = (WrappedComponent) =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component'

const withRails = (Component) => {
  const WithRailsComponent = ({children}) => children
  WithRailsComponent.displayName = `WithRails(${getDisplayName(Component)}`
  // eslint-disable-next-line react/display-name
  return (props) => (
    <WithRailsComponent>
      <RailsProvider {...props}>
        <Component {...props} />
      </RailsProvider>
    </WithRailsComponent>
  )
}

export {RailsProvider, useRailsContext, withRails}

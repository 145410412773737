import React from 'react'
import PropTypes from 'prop-types'
import {Flex, Text, Icon, Link} from '@chakra-ui/react'

const OptionMenu = ({name, icon, ...props}) => (
  <Link {...props}>
    <Flex align="center">
      <Icon as={icon} h="16px" w="16px" me="8px" />
      <Text fontSize="sm" fontWeight="400">
        {name}
      </Text>
    </Flex>
  </Link>
)

OptionMenu.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
}

export default OptionMenu

import {
  Flex,
  Text,
  Grid,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import {
  ArrowRightIcon,
  ArrowLeftIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  ChevronDownIcon,
} from '@chakra-ui/icons'
import React, {useCallback} from 'react'
import PropTypes from 'prop-types'
import Tooltip from '../tooltip/Tooltip'

const ONE_ROW_WIDTH_NEEDED = 500

const PaginationBar = ({
  width,
  gotoPage,
  canPreviousPage,
  previousPage,
  nextPage,
  pageIndex,
  pageOptions,
  canNextPage,
  pageCount,
  pageSize,
  setPageSize,
}) => {
  const gridTemplate =
    width >= ONE_ROW_WIDTH_NEEDED ? 'repeat(4, 1fr)' : 'repeat(3, 1fr)'
  const oneRow = width >= ONE_ROW_WIDTH_NEEDED

  const handleSelectOption = useCallback((event) => {
    setPageSize(Number(event.target.textContent))
  }, [])

  return (
    <Grid
      gridTemplateColumns={{
        base: 'repeat(3, 1fr)',
        md: gridTemplate,
      }}
      display="grid"
      justifyContent="space-between"
      alignItems="center"
      w={'90%'}
      alignSelf="center"
      position={'absolute'}
      bottom={{base: '6%', md: '8%', lg: '3%'}}
    >
      <Flex
        gridArea={{
          base: '1 / 1 / 2 / 2',
          md: oneRow ? '1 / 1 / 2 / 2' : '1 / 1 / 2 / 2',
        }}
        justify="center"
      >
        <Tooltip
          label="First Page"
          onClick={() => gotoPage(0)}
          isDisabled={!canPreviousPage}
          icon={<ArrowLeftIcon h={3} w={3} />}
          mr={4}
        />
        <Tooltip
          label="Previous Page"
          onClick={previousPage}
          isDisabled={!canPreviousPage}
          icon={<ChevronLeftIcon h={6} w={6} />}
        />
      </Flex>

      <Flex
        alignItems="center"
        gridArea={{
          base: '1 / 2 / 2 / 3',
          md: oneRow ? '1 / 2 / 2 / 3' : '1 / 2 / 2 / 3',
        }}
        justify={oneRow ? 'flex-start' : 'center'}
      >
        <Text flexShrink="0" mr={8}>
          Page
          <Text data-testid="currentPage" fontWeight="bold" as="span" p={1}>
            {pageIndex + 1}
          </Text>
          of
          <Text data-testid="numberOfPages" fontWeight="bold" as="span" pl={1}>
            {pageOptions.length}
          </Text>
        </Text>
      </Flex>

      <Flex
        gridArea={{
          base: '1 / 3 / 2 / 4',
          md: oneRow ? '1 / 4 / 2 / 5' : '1 / 3 / 2 / 4',
        }}
        justify="center"
      >
        <Tooltip
          label="Next Page"
          onClick={nextPage}
          disabled={!canNextPage}
          icon={<ChevronRightIcon h={6} w={6} />}
        />
        <Tooltip
          label="Last Page"
          onClick={() => gotoPage(pageCount - 1)}
          isDisabled={!canNextPage}
          icon={<ArrowRightIcon h={3} w={3} />}
          ml={4}
        />
      </Flex>

      <Flex
        gridArea={{
          base: '2 / 1 / 3 / 4',
          md: oneRow ? '1 / 3 / 2 / 4' : '2 / 1 / 3 / 4',
        }}
        justify={{base: 'center', md: 'flex-end'}}
        align="center"
        pt={oneRow ? 0 : 2}
      >
        <Text flexShrink="0" pr={1}>
          Show:
        </Text>
        <Menu>
          <MenuButton
            data-testid="elementsPerPageSelect"
            w={24}
            ml={oneRow ? 3 : 0}
            as={Button}
            rightIcon={<ChevronDownIcon />}
          >
            {pageSize}
          </MenuButton>
          <MenuList>
            {[10, 25, 50].map((pageSize) => (
              <MenuItem
                data-testid="elementsPerPageOption"
                key={pageSize}
                onClick={handleSelectOption}
              >
                {pageSize}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
    </Grid>
  )
}

PaginationBar.defaultProps = {
  width: 0,
}

PaginationBar.propTypes = {
  width: PropTypes.number,
  gotoPage: PropTypes.func.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  previousPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageOptions: PropTypes.array.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
}

export default PaginationBar

import React from 'react'
import PropTypes from 'prop-types'

// Custom components
import TableReport from '../../../components/reports/TableReport'

const Ranks = ({name}) => {
  const ranksTableColumns = [
    {
      header: 'rank',
      accessor: 'rank_description',
    },
  ]

  return <TableReport name={name} dataColumns={ranksTableColumns} />
}

Ranks.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Ranks

import React, {useCallback} from 'react'
import PropTypes from 'prop-types'

// Custom components
import BarChartReport from '../../../components/reports/BarChartReport'

const AmountPaidByBonus = ({name}) => {
  const amountPaidByBonusChartData = useCallback(
    (data) => data?.map((entry) => entry.amount),
    [],
  )

  const amountPaidByBonusChartOptions = useCallback(
    (data) => data?.map((entry) => `${entry.bonus_description}`),
    [],
  )

  return (
    <BarChartReport
      name={name}
      subtitle="Rolling Year"
      filterData={amountPaidByBonusChartData}
      filterOptions={amountPaidByBonusChartOptions}
      showLabels={false}
    />
  )
}

AmountPaidByBonus.propTypes = {
  name: PropTypes.string.isRequired,
}

export default AmountPaidByBonus

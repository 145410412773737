import React from 'react'
import PropTypes from 'prop-types'

import {
  Icon,
  useColorModeValue,
  Button,
  useBreakpoint,
  IconButton,
} from '@chakra-ui/react'
import {MdFilterAlt} from 'react-icons/md'
import {Menu, MenuList} from '../menu'
import FilterDate from './FilterDate'

const DatesFilter = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  ...props
}) => {
  const breakpoint = useBreakpoint()

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value)
  }

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value)
  }
  const iconColor = useColorModeValue('brand.500', 'white')

  const isSmall =
    breakpoint === '2sm' ||
    breakpoint === 'sm' ||
    breakpoint === 'base' ||
    breakpoint === 'md'

  return (
    <Menu
      closeOnSelect={false}
      w={{base: '30px', md: 'auto'}}
      as={isSmall ? IconButton : Button}
      rightIcon={
        !isSmall ? <Icon margin={0} as={MdFilterAlt} color={iconColor} /> : null
      }
      icon={
        isSmall ? <Icon margin={0} as={MdFilterAlt} color={iconColor} /> : null
      }
      title={!isSmall ? 'Filter' : ''}
      {...props}
    >
      <MenuList
        containerProps={{maxH: '200px', flexDir: 'row', display: 'flex'}}
        optionsProps={{width: 'auto'}}
        options={[
          {
            date: startDate,
            handleDateChange: handleStartDateChange,
            header: 'From',
            key: 'startDate',
          },
          {
            date: endDate,
            handleDateChange: handleEndDateChange,
            header: 'To',
            key: 'endDate',
          },
        ]}
        renderOption={FilterDate}
      />
    </Menu>
  )
}

DatesFilter.defaultProps = {
  startDate: '',
  setStartDate: () => {},
  endDate: '',
  setEndDate: () => {},
}

DatesFilter.propTypes = {
  startDate: PropTypes.string,
  setStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.string,
  setEndDate: PropTypes.func.isRequired,
}

export default DatesFilter

import React, {useCallback} from 'react'
import PropTypes from 'prop-types'

// Custom components
import BarChartReport from '../../../components/reports/BarChartReport'

import {formatDate, oneYearAgo} from '../../../utils/formatter'

const CommissionsByMonth = ({name}) => {
  const formatedOneYearAgo = formatDate(oneYearAgo())
  const formatedToday = formatDate(new Date())

  const commissionsByMonthChartData = useCallback(
    (data) => data?.map((entry) => entry.total),
    [],
  )

  const commissionsByMonthChartOptions = useCallback(
    (data) => data?.map((entry) => `${entry.month}/${entry.year}`),
    [],
  )

  return (
    <BarChartReport
      name={name}
      filterData={commissionsByMonthChartData}
      filterOptions={commissionsByMonthChartOptions}
      defaultStartDate={formatedOneYearAgo}
      defaultEndDate={formatedToday}
      showReportsFilter
    />
  )
}

CommissionsByMonth.propTypes = {
  name: PropTypes.string.isRequired,
}

export default CommissionsByMonth

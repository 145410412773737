import React, {useCallback} from 'react'
import PropTypes from 'prop-types'

// Custom components
import BarChartReport from '../../../components/reports/BarChartReport'

const PurchasesPerCustomerRollingYear = ({name}) => {
  const purchasesChartData = useCallback(
    (data) => data?.map((entry) => entry.customer_count),
    [],
  )

  const purchasesChartOptions = useCallback(
    (data) => data?.map((entry) => `${entry.number_of_orders_group}`),
    [],
  )

  return (
    <BarChartReport
      name={name}
      filterData={purchasesChartData}
      filterOptions={purchasesChartOptions}
      columnsWidth={'20%'}
    />
  )
}

PurchasesPerCustomerRollingYear.propTypes = {
  name: PropTypes.string.isRequired,
}

export default PurchasesPerCustomerRollingYear

import React, {useState, useEffect} from 'react'
import Chart from 'react-apexcharts'

function BarChart(props) {
  const [chartData, setChartData] = useState([])
  const [chartOptions, setChartOptions] = useState({})

  useEffect(() => {
    setChartData(props.chartData)
    setChartOptions(props.chartOptions)
  }, [props.chartData, props.chartOptions]) // Dependency array to update state when props change

  return (
    <Chart
      data-testid="barchart"
      options={chartOptions}
      series={chartData}
      type="bar"
      width="100%"
      height="100%"
    />
  )
}

export default BarChart

import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useGlobalFilter, usePagination, useSortBy, useTable} from 'react-table'
import PaginationBar from '../paginationBar/PaginationBar'

const ColumnsTable = ({columnsData, tableData}) => {
  const columns = useMemo(() => columnsData, [columnsData])
  const data = useMemo(() => tableData, [tableData])

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize},
  } = tableInstance
  initialState.pageSize = 10

  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100')
  const tableRef = useRef()
  const [width, setWidth] = useState(0)

  const handleWidth = useCallback(() => {
    setWidth(tableRef?.current?.offsetWidth || 0)
  }, [tableRef])

  useEffect(() => {
    handleWidth()
    window.addEventListener('resize', handleWidth)
    return () => {
      window?.removeEventListener('resize', handleWidth)
    }
  }, [])

  return (
    <Flex w={'100%'} flexDir={'column'} overflowX="auto">
      <Table
        ref={tableRef}
        data-testid="columnstable"
        variant="simple"
        color="gray.500"
        mb="90px"
        {...getTableProps()}
        pos={'relative'}
      >
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify={columns?.length === 1 ? 'center' : 'space-between'}
                    align="center"
                    fontSize={{sm: '10px', lg: '12px'}}
                    color="gray.400"
                    whiteSpace="nowrap"
                  >
                    {column.render('header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row)
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  const data = (
                    <Flex
                      align="center"
                      justify={
                        columns?.length === 1 ? 'center' : 'space-between'
                      }
                    >
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    </Flex>
                  )
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{sm: '14px'}}
                      minW={{sm: '150px', md: '200px', lg: 'auto'}}
                      borderColor="transparent"
                    >
                      {data}
                    </Td>
                  )
                })}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      <PaginationBar
        width={width}
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        nextPage={nextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </Flex>
  )
}

export default ColumnsTable

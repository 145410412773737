import React, {useCallback, useMemo, useState} from 'react'

import PropTypes from 'prop-types'

// Custom components
import {chartData, barChartOptions} from '../../variables/charts'
import BarChart from '../../components/charts/BarChart'
import ReportLayout from './ReportLayout'

const BarChartReport = ({
  name,
  title,
  filterOptions,
  filterData,
  showLabels,
  columnsWidth,
  ...props
}) => {
  const [data, setData] = useState([])

  const handleData = useCallback((data) => setData(data), [])

  const barChartData = useMemo(
    () =>
      !!data && data?.length > 0
        ? chartData(title || name, filterData(data))
        : [
            {
              name: title || name,
              data: [],
            },
          ],
    [data, name, title, filterData],
  )

  const chartOptions = useMemo(
    () =>
      !!data && data?.length > 0
        ? barChartOptions(filterOptions(data), showLabels, columnsWidth)
        : {},
    [data, filterOptions, showLabels, columnsWidth],
  )

  return (
    <ReportLayout name={name} title={title} handleData={handleData} {...props}>
      <BarChart chartData={barChartData} chartOptions={chartOptions} />
    </ReportLayout>
  )
}

BarChartReport.defaultProps = {
  title: undefined,
  showLabels: true,
  columnsWidth: '70%',
}

BarChartReport.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  filterData: PropTypes.func.isRequired,
  filterOptions: PropTypes.func.isRequired,
  showLabels: PropTypes.bool,
  columnsWidth: PropTypes.string,
}

export default BarChartReport

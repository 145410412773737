import React from 'react'
import PropTypes from 'prop-types'

// Custom components
import KpiReport from '../../../components/reports/KpiReport'

const PreferredCustomerEnrollmentsByTimePeriod = ({name}) => {
  const titlePrefix = 'Preferred Customer Enrollments By'

  return (
    <KpiReport name={name} titlePrefix={titlePrefix} subtitle="Performance" />
  )
}

PreferredCustomerEnrollmentsByTimePeriod.propTypes = {
  name: PropTypes.string.isRequired,
}

export default PreferredCustomerEnrollmentsByTimePeriod

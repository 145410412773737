import React, {useCallback} from 'react'

import PropTypes from 'prop-types'

// Custom components
import BarChartReport from '../../../components/reports/BarChartReport'

const AutoshipsByFrequency = ({name}) => {
  const autoshipsByFrequencyChartData = useCallback(
    (data) => data?.map((entry) => entry.total),
    [],
  )

  const autoshipsByFrequencyChartOptions = useCallback(
    (data) => data?.map((entry) => `${entry.frequency_type_description}`),
    [],
  )

  return (
    <BarChartReport
      name={name}
      filterData={autoshipsByFrequencyChartData}
      filterOptions={autoshipsByFrequencyChartOptions}
    />
  )
}

AutoshipsByFrequency.propTypes = {
  name: PropTypes.string.isRequired,
}

export default AutoshipsByFrequency

import React from 'react'
import PropTypes from 'prop-types'

// Custom components
import TableReport from '../../../components/reports/TableReport'

const RecentProductPurchases = ({name}) => {
  const recentProductPurchasesTableColumns = [
    {
      header: 'Name',
      accessor: 'name',
    },
    {
      header: 'Order Date',
      accessor: 'order_date',
      is_date: true,
    },
    {
      header: 'Product',
      accessor: 'product_description',
    },
  ]

  return (
    <TableReport name={name} dataColumns={recentProductPurchasesTableColumns} />
  )
}

RecentProductPurchases.propTypes = {
  name: PropTypes.string.isRequired,
}

export default RecentProductPurchases

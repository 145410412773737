import React, {useCallback, useState} from 'react'

import PropTypes from 'prop-types'

// Chakra imports
import {
  MenuList as ChakraMenuList,
  MenuItem,
  MenuOptionGroup,
  MenuItemOption,
  useColorModeValue,
} from '@chakra-ui/react'

const MenuList = ({
  options = [],
  checkboxes = false,
  onChange,
  containerProps,
  optionsProps,
  renderOption,
}) => {
  const [checkedOptions, setCheckedOptions] = useState([])
  const [selected, setSelected] = useState()

  const handleChangeCheckboxes = useCallback(
    (checkedValues) => {
      onChange(
        checkedValues.map((key) => ({
          key: key,
          name: options?.find((e) => e.key === key)?.name,
        })),
      )
      setCheckedOptions(checkedValues)
    },
    [options],
  )

  const handleSelected = useCallback(
    (selectedValue) => {
      if (onChange) {
        onChange(selectedValue)
      }
      setSelected(selectedValue?.key)
    },
    [onChange],
  )

  const textColor = useColorModeValue('secondaryGray.500', 'white')
  const textHighlighted = useColorModeValue(
    {color: 'secondaryGray.900', bg: 'unset'},
    {color: 'secondaryGray.500', bg: 'unset'},
  )

  const bgList = useColorModeValue('white', 'whiteAlpha.100')
  const bgShadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
    'unset',
  )

  const colorsConfig = {
    color: textColor,
    _hover: textHighlighted,
    _active: {
      bg: 'transparent',
    },
    _focus: {
      bg: 'transparent',
    },
  }

  const menuItemProps = {
    transition: '0.2s linear',
    borderRadius: '8px',
    ...colorsConfig,
  }

  return (
    <ChakraMenuList
      border="transparent"
      backdropFilter="blur(63px)"
      bg={bgList}
      boxShadow={bgShadow}
      borderRadius="20px"
      p="15px"
      zIndex={100}
      overflowY={checkboxes && 'scroll'}
      {...containerProps}
    >
      {checkboxes ? (
        <MenuOptionGroup
          type="checkbox"
          onChange={handleChangeCheckboxes}
          value={checkedOptions}
        >
          {options.map((option) => (
            <MenuItemOption
              {...colorsConfig}
              key={option.key}
              value={option.key}
            >
              {option.name}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      ) : (
        options.map((option, index) => (
          <MenuItem
            {...menuItemProps}
            {...optionsProps}
            key={option?.key || index}
            color={selected === option?.key ? textHighlighted : textColor}
            onClick={() => handleSelected(option)}
          >
            {renderOption(option)}
          </MenuItem>
        ))
      )}
    </ChakraMenuList>
  )
}

MenuList.defaultProps = {
  options: [],
  checkboxes: false,
  containerProps: {},
  optionsProps: {},
  onChange: () => {},
  renderOption: () => {},
}

MenuList.propTypes = {
  options: PropTypes.array,
  checkboxes: PropTypes.bool,
  containerProps: PropTypes.object,
  optionsProps: PropTypes.object,
  onChange: PropTypes.func,
  renderOption: PropTypes.func,
}

export default MenuList

import React from 'react'

import {withRails} from '../../contexts/RailsContext'
import withSWR from '../../contexts/SwrContext'

// Chakra imports
import {Flex, Grid, useColorModeValue} from '@chakra-ui/react'

// Custom components
import AdminLayout from '../../layouts/Admin'
import {VSeparator} from '../../components/separator/Separator'
import AutoshipsByMonth from './components/AutoshipsByMonth'
import NewAutoshipsByMonth from './components/NewAutoshipsByMonth'
import TopProductsInAutoships from './components/TopProductsInAutoships'
import AutoshipsByFrequency from './components/AutoshipsByFrequency'
import UpcomingAutoships from './components/UpcomingAutoships'
import AverageAutoDeliveryMonthsByRegion from './components/AverageAutoDeliveryMonthsByRegion'
import Report from '../../components/reports/Report'

export const AutoshipsScreen = () => {
  // Chakra Color Mode
  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100')

  return (
    <AdminLayout>
      <Flex
        direction={{base: 'column', xl: 'row'}}
        pt={{base: '130px', md: '80px', xl: '80px'}}
      >
        <Flex direction="column" width="stretch">
          <Report name={'Autoships by Month'} component={AutoshipsByMonth} />
          <Grid
            gap="20px"
            templateColumns={{
              md: 'repeat(auto-fit, minmax(250px, 1fr))',
            }}
          >
            <Report
              name={'New Autoships by Month'}
              component={NewAutoshipsByMonth}
            />
            <Report
              name={'Top Products in Autoships'}
              component={TopProductsInAutoships}
            />
            <Report
              name={'Autoships by Frequency'}
              component={AutoshipsByFrequency}
            />
          </Grid>
          <Report name={'Upcoming Autoships'} component={UpcomingAutoships} />
          <Report
            name={'Average Auto Delivery Months by Region'}
            component={AverageAutoDeliveryMonthsByRegion}
          />
        </Flex>
        <VSeparator
          mx="20px"
          bg={paleGray}
          display={{base: 'none', xl: 'flex'}}
        />
      </Flex>
    </AdminLayout>
  )
}

AutoshipsScreen.propTypes = {}

export default withRails(withSWR(AutoshipsScreen))

import React from 'react'
import PropTypes from 'prop-types'

import {Box, Spinner} from '@chakra-ui/react'

const Loader = ({isLoading, children, overflowX = ''}) => {
  return (
    <>
      {isLoading ? (
        <Box
          minH="260px"
          mt="auto"
          w="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner
            size="xl"
            color="var(--chakra-colors-brand-500)"
            data-testid="spinner"
          />
        </Box>
      ) : (
        <Box
          minH="260px"
          w="100%"
          overflowX={overflowX}
          justifyContent="flex-start"
        >
          {children}
        </Box>
      )}
    </>
  )
}

Loader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  overflowX: PropTypes.string,
}

export default Loader

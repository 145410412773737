import React from 'react'
import {Icon} from '@chakra-ui/react'

import {
  MdDashboard,
  MdFormatListBulleted,
  MdLocalShipping,
  MdMonetizationOn,
  MdAccountCircle,
} from 'react-icons/md'

export const routes = (plan) => {
  return [
    {
      name: 'Dashboards',
      path: '/',
      icon: (
        <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />
      ),
      collapse: false,
    },
    {
      name: 'Orders',
      path: '/orders',
      icon: (
        <Icon
          as={MdFormatListBulleted}
          width="20px"
          height="20px"
          color="inherit"
        />
      ),
      collapse: false,
    },
    {
      name: 'Autoships',
      path: '/autoships',
      icon: (
        <Icon as={MdLocalShipping} width="20px" height="20px" color="inherit" />
      ),
      collapse: false,
    },
    {
      name: 'Commissions',
      path: '/commissions',
      icon: (
        <Icon
          as={MdMonetizationOn}
          width="20px"
          height="20px"
          color="inherit"
        />
      ),
      collapse: false,
    },
    {
      name: "Comparable KPI's",
      path: '/kpi',
      disable: !plan?.kpi,
      icon: (
        <Icon
          as={MdMonetizationOn}
          width="20px"
          height="20px"
          color="inherit"
        />
      ),
      collapse: false,
    },
    {
      name: 'Profile',
      path: '/organisation_profile',
      disable: true,
      icon: (
        <Icon as={MdAccountCircle} width="20px" height="20px" color="inherit" />
      ),
      collapse: false,
    },
  ]
}

import React, {useCallback} from 'react'

import PropTypes from 'prop-types'

// Custom components
import LineChartReport from '../../../components/reports/LineChartReport'
import {chartOptions} from '../../../variables/charts'

const MTDTotalSalesVsPriorMTDTotalSales = ({name}) => {
  const filterDataCurrentTotalSales = useCallback(
    (data) => data.map((entry) => entry.current_total),
    [],
  )

  const filterDataPriorTotalSales = useCallback(
    (data) => data.map((entry) => entry.prior_total),
    [],
  )

  const filterOptions = useCallback(
    (data) => chartOptions(data?.map((entry) => `${entry.day}`)),
    [],
  )

  return (
    <LineChartReport
      name={name}
      chartOptions={filterOptions}
      linesConfig={[
        {
          name: 'MTD Current Total Sales',
          filterData: filterDataCurrentTotalSales,
        },
        {
          name: 'MTD Prior Total Sales',
          filterData: filterDataPriorTotalSales,
        },
      ]}
    />
  )
}

MTDTotalSalesVsPriorMTDTotalSales.propTypes = {
  name: PropTypes.string.isRequired,
}

export default MTDTotalSalesVsPriorMTDTotalSales

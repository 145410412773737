import React, {useCallback} from 'react'
import PropTypes from 'prop-types'

// Custom components
import BarChartReport from '../../../components/reports/BarChartReport'

const AverageRankAdvancementTime = ({name}) => {
  const chartName = 'Average Time To Advance Beyond Each Rank'

  const averageRankAdvancementTimeChartData = useCallback(
    (data) => data?.map((entry) => entry.average),
    [],
  )

  const averageRankAdvancementTimeChartOptions = useCallback(
    (data) => data?.map((entry) => `${entry.rank_description}`),
    [],
  )

  return (
    <BarChartReport
      name={name}
      title={chartName}
      subtitle="Performance"
      filterData={averageRankAdvancementTimeChartData}
      filterOptions={averageRankAdvancementTimeChartOptions}
      showLabels={false}
    />
  )
}

AverageRankAdvancementTime.propTypes = {
  name: PropTypes.string.isRequired,
}

export default AverageRankAdvancementTime

import React, {useState, useEffect} from 'react'
import ReactApexChart from 'react-apexcharts'

function LineChart(props) {
  const [chartData, setChartData] = useState([])
  const [chartOptions, setChartOptions] = useState({})

  useEffect(() => {
    setChartData(props.chartData)
    setChartOptions(props.chartOptions)
  }, [props.chartData, props.chartOptions]) // Dependency array to update state when props change

  return (
    <ReactApexChart
      data-testid="linechart"
      options={chartOptions}
      series={chartData}
      type="line"
      width="100%"
      height="100%"
    />
  )
}

export default LineChart

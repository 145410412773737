import React, {useCallback} from 'react'

import PropTypes from 'prop-types'

// Custom components
import BarChartReport from '../../../components/reports/BarChartReport'

const TopProductsInAutoships = ({name}) => {
  const topProductsInAutoshipsChartData = useCallback(
    (data) => data?.map((entry) => entry.total),
    [],
  )

  const topProductsInAutoshipsChartOptions = useCallback(
    (data) => data?.map((entry) => `${entry.item_description}`),
    [],
  )

  return (
    <BarChartReport
      name={name}
      filterData={topProductsInAutoshipsChartData}
      filterOptions={topProductsInAutoshipsChartOptions}
    />
  )
}

TopProductsInAutoships.propTypes = {
  name: PropTypes.string.isRequired,
}

export default TopProductsInAutoships

import React, {useCallback, useState} from 'react'
import PropTypes from 'prop-types'

// Custom components
import {chartOptions} from '../../../variables/charts'

// Custom components
import LineChartReport from '../../../components/reports/LineChartReport'

const SignUpsByCustomerType = ({name}) => {
  const [linesConfig, setLinesConfig] = useState([])

  const filterCustomerType = useCallback(
    (customerType) => (data) => {
      const options = data
        .map((element) => `${element.month}/${element.year}`)
        .filter((element, pos, arr) => {
          return arr.indexOf(element) == pos
        })

      return options.map((option) => {
        let row = data
          .filter(
            (element) => element.customer_type_description == customerType,
          )
          .find((element) => option == `${element.month}/${element.year}`)
        return row?.customer_count || 0
      })
    },
    [],
  )

  const signUpsByCustomerTypeOptions = useCallback(
    (data) =>
      chartOptions(
        data
          .map((element) => `${element.month}/${element.year}`)
          .filter((element, pos, arr) => {
            return arr.indexOf(element) == pos
          }),
      ),
    [],
  )

  const handleLinesConfig = useCallback(
    (data) => {
      const customerTypes = data.reduce(
        (acc, curr) =>
          acc.includes(curr.customer_type_description)
            ? acc
            : [...acc, curr.customer_type_description],
        [],
      )
      setLinesConfig(
        customerTypes.map((element) => ({
          name: element,
          filterData: filterCustomerType(element),
        })),
      )
    },
    [filterCustomerType],
  )

  return (
    <LineChartReport
      name={name}
      chartOptions={signUpsByCustomerTypeOptions}
      linesConfig={linesConfig}
      dataCallback={handleLinesConfig}
    />
  )
}

SignUpsByCustomerType.defaultProps = {
  name: '',
}

SignUpsByCustomerType.propTypes = {
  name: PropTypes.string.isRequired,
}

export default SignUpsByCustomerType

import React from 'react'

import {withRails, useRailsContext} from '../../contexts/RailsContext'

// Chakra imports
import {Box, Flex, useToast} from '@chakra-ui/react'

// Custom components
import AdminLayout from '../../layouts/Admin'
import Profile from './components/Profile'
import Info from './components/Info'

export const OrganisationProfile = () => {
  const {permissionMessages} = useRailsContext()

  const toast = useToast()

  if (permissionMessages) {
    toast({
      title: 'You cannot perform this action.',
      description: permissionMessages,
      status: 'error',
      duration: 9000,
      isClosable: true,
    })
  }

  return (
    <AdminLayout>
      <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
        <Flex direction="column">
          <Profile />
          <Info />
        </Flex>
      </Box>
    </AdminLayout>
  )
}

OrganisationProfile.propTypes = {}

export default withRails(OrganisationProfile)

import React from 'react'
import PropTypes from 'prop-types'

import {useFormik} from 'formik'
import * as Yup from 'yup'

// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  Text,
} from '@chakra-ui/react'

import {MdOutlineRemoveRedEye} from 'react-icons/md'
import {RiEyeCloseLine} from 'react-icons/ri'

const SignInForm = ({authenticityToken}) => {
  const textColor = useColorModeValue('navy.700', 'white')
  const textColorSecondary = 'gray.400'
  const brandStars = useColorModeValue('brand.500', 'brand.400')

  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)

  const {touched, values, handleSubmit, handleBlur, handleChange, errors} =
    useFormik({
      initialValues: {
        organisation_login: {
          email: '',
          password: '',
          remember_me: false,
        },
      },
      validationSchema: Yup.object().shape({
        organisation_login: Yup.object({
          email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
          password: Yup.string().required('Required'),
        }),
      }),
      onSubmit: () => {
        // simulate real click event
        document.querySelector('form').submit()
      },
    })

  return (
    <Box>
      <form
        onSubmit={handleSubmit}
        method="POST"
        acceptCharset="UTF-8"
        action={'/organisation_logins/sign_in'}
      >
        <input
          type="hidden"
          name="authenticity_token"
          value={authenticityToken}
        />
        <input type="hidden" name="commit" value="Login" />

        <FormControl
          isInvalid={
            touched.organisation_login &&
            touched.organisation_login.email &&
            errors.organisation_login &&
            errors.organisation_login.email
          }
        >
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
            htmlFor="organisation_login_email"
          >
            Email<Text color={brandStars}>*</Text>
          </FormLabel>
          {errors.organisation_login && errors.organisation_login.email && (
            <FormErrorMessage>
              {errors.organisation_login.email}
            </FormErrorMessage>
          )}
          <Input
            isRequired={true}
            variant="auth"
            fontSize="sm"
            ms={{base: '0px', md: '0px'}}
            mb="24px"
            fontWeight="500"
            size="lg"
            type="email"
            name="organisation_login[email]"
            id="organisation_login_email"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="your@email.com"
            value={values.organisation_login.email}
          />

          <FormControl
            isInvalid={
              touched.organisation_login &&
              touched.organisation_login.password &&
              errors.organisation_login &&
              errors.organisation_login.password
            }
            mb={5}
          >
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
              htmlFor="organisation_login_password"
            >
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            {errors.organisation_login &&
              errors.organisation_login.password && (
                <FormErrorMessage>
                  {errors.organisation_login.password}
                </FormErrorMessage>
              )}
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                ms={{base: '0px', md: '4px'}}
                placeholder="Min. 8 characters"
                mb="24px"
                size="lg"
                type={show ? 'text' : 'password'}
                variant="auth"
                name="organisation_login[password]"
                id="organisation_login_password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.organisation_login.password}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{cursor: 'pointer'}}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                  data-testid="password-view"
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Flex justifyContent="space-between" align="center" mb="24px">
            <FormControl display="flex" alignItems="center">
              <Checkbox
                id="remember-login"
                colorScheme="brand"
                me="10px"
                name="organisation_login[remember_me]"
              />
              <FormLabel
                htmlFor="remember-login"
                mb="0"
                fontWeight="normal"
                color={textColor}
                fontSize="sm"
              >
                Keep me logged in
              </FormLabel>
            </FormControl>
            {/* <Box to="/auth/forgot-password">
              <Text
                color={textColorBrand}
                fontSize="sm"
                w="124px"
                fontWeight="500"
              >
                Forgot password?
              </Text>
            </Box> */}
          </Flex>
          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50"
            mb="24px"
            type="submit"
            data-testid="signin-login"
          >
            Sign In
          </Button>
        </FormControl>
      </form>
    </Box>
  )
}

SignInForm.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
}
export default SignInForm

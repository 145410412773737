import React from 'react'
import PropTypes from 'prop-types'

// Custom components
import TableReport from '../../../components/reports/TableReport'

const Bonuses = ({name}) => {
  const bonusesTableColumns = [
    {
      header: 'Bonus name',
      accessor: 'bonus_description',
    },
  ]

  return <TableReport name={name} dataColumns={bonusesTableColumns} />
}

Bonuses.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Bonuses

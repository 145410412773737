import React, {useCallback, useState} from 'react'

import PropTypes from 'prop-types'

// Custom components
import {chartOptions} from '../../../variables/charts'
import LineChartReport from '../../../components/reports/LineChartReport'
import {formatDate, oneYearAgo} from '../../../utils/formatter'

const AverageAutoDeliveryMonthsByRegion = ({name}) => {
  const formatedOneYearAgo = formatDate(oneYearAgo())
  const formatedToday = formatDate(new Date())

  const [countriesToShow, setCountriesToShow] = useState([])

  const averageAutoDeliveryMonthsOptions = useCallback(
    (data) => {
      const uniqueDates = data?.reduce(
        (acc, curr) =>
          acc.includes(`${curr.month}/${curr.year}`)
            ? acc
            : [...acc, `${curr.month}/${curr.year}`],
        [],
      )
      const sortedUniqueDates = uniqueDates?.sort(
        (a, b) =>
          new Date(a.replace('/', '/1/')) - new Date(b.replace('/', '/1/')),
      )
      return chartOptions(sortedUniqueDates)
    },
    [chartOptions],
  )

  const onChange = useCallback((countries) => {
    setCountriesToShow(
      countries?.map((country) => ({
        name: country?.name,
        filterData: (data) =>
          data
            ?.filter((entry) => entry.country === country?.key)
            ?.map((element) => element.avg_orders),
      })),
    )
  }, [])

  return (
    <LineChartReport
      name={name}
      chartOptions={averageAutoDeliveryMonthsOptions}
      linesConfig={countriesToShow}
      showCountriesSelect
      onSelectCountriesChange={onChange}
      defaultStartDate={formatedOneYearAgo}
      defaultEndDate={formatedToday}
      showReportsFilter
    />
  )
}

AverageAutoDeliveryMonthsByRegion.propTypes = {
  name: PropTypes.string.isRequired,
}

export default AverageAutoDeliveryMonthsByRegion

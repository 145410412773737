import React, {useCallback} from 'react'

import PropTypes from 'prop-types'

// Custom components
import BarChartReport from '../../../components/reports/BarChartReport'

const MTDQuantitySoldByItem = ({name}) => {
  const mtdquantitySoldByItemChartData = useCallback(
    (data) => data?.map((entry) => entry.quantity),
    [],
  )

  const mtdquantitySoldByItemChartOptions = useCallback(
    (data) => data?.map((entry) => `${entry.item}`),
    [],
  )

  return (
    <BarChartReport
      name={name}
      filterData={mtdquantitySoldByItemChartData}
      filterOptions={mtdquantitySoldByItemChartOptions}
    />
  )
}

MTDQuantitySoldByItem.propTypes = {
  name: PropTypes.string.isRequired,
}

export default MTDQuantitySoldByItem

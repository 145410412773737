import React from 'react'

import {withRails} from '../../contexts/RailsContext'
import withSWR from '../../contexts/SwrContext'

// Chakra imports
import {Flex, useColorModeValue} from '@chakra-ui/react'

// Custom components
import AdminLayout from '../../layouts/Admin'
import {VSeparator} from '../../components/separator/Separator'

import OrdersByTimeFrame from './components/OrdersByTimeFrame'
import SalesByTimeFrame from './components/SalesByTimeFrame'
import Filters from './components/Filters'

import {KpiContextProvider} from '../../contexts/KpiContext'
import CartSizeByTimePeriod from './components/CartSizeByTimePeriod'
import EnrollmentsByTimePeriod from './components/EnrollmentsByTimePeriod'

import AffiliateEnrollmentsByTimePeriod from './components/AffiliateEnrollmentsByTimePeriod'
import AmbassadorEnrollmentsByTimePeriod from './components/AmbassadorEnrollmentsByTimePeriod'
import PreferredCustomerEnrollmentsByTimePeriod from './components/PreferredCustomerEnrollmentsByTimePeriod'

import CustomerEnrollmentsByTimePeriod from './components/CustomerEnrollmentsByTimePeriod'
import IboEnrollmentsByTimePeriod from './components/IboEnrollmentsByTimePeriod'
import Report from '../../components/reports/Report'

export const Kpi = () => {
  // Chakra Color Mode
  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100')

  return (
    <AdminLayout>
      <KpiContextProvider>
        <Flex
          direction={{base: 'column', xl: 'row'}}
          pt={{base: '130px', md: '80px', xl: '80px'}}
        >
          <Flex direction="column" width="stretch">
            <Filters />

            {/* charts by country and time period */}
            <Report
              name={'Kpi Orders by Time Frame'}
              component={OrdersByTimeFrame}
            />

            <Report
              name={'Kpi Sales by Time Frame'}
              component={SalesByTimeFrame}
            />

            <Report
              name={'Kpi Cart Size by Time Period'}
              component={CartSizeByTimePeriod}
            />

            <Report
              name={'Kpi BP Enrollments by Time Period'}
              component={EnrollmentsByTimePeriod}
            />

            <Report
              name={'Kpi Ambassador Enrollments by Time Period'}
              component={AmbassadorEnrollmentsByTimePeriod}
            />
            <Report
              name={'Kpi Preferred Customer Enrollments by Time Period'}
              component={PreferredCustomerEnrollmentsByTimePeriod}
            />
            <Report
              name={'Kpi Affiliate Enrollments by Time Period'}
              component={AffiliateEnrollmentsByTimePeriod}
            />
            <Report
              name={'Kpi Customer Enrollments by Time Period'}
              component={CustomerEnrollmentsByTimePeriod}
            />

            <Report
              name={'Kpi IBOs With Enrollments By Time Period'}
              component={IboEnrollmentsByTimePeriod}
            />
          </Flex>
          <VSeparator
            mx="20px"
            bg={paleGray}
            display={{base: 'none', xl: 'flex'}}
          />
        </Flex>
      </KpiContextProvider>
    </AdminLayout>
  )
}

Kpi.propTypes = {}

export default withRails(withSWR(Kpi))

import React, {useCallback} from 'react'

import PropTypes from 'prop-types'

// Custom components
import LineChartReport from '../reports/LineChartReport'
import {formatTimePeriod} from '../../utils/formatter'

import {useKpiContext} from '../../contexts/KpiContext'
import {chartOptions} from '../../variables/charts'

const KpiReport = ({
  name,
  title,
  titlePrefix,
  subtitle,
  defaultStartDate,
  defaultEndDate,
  showReportsFilter,
}) => {
  const {timePeriod, country} = useKpiContext()
  let params = `country=${country}&time_frame=${timePeriod}`

  const nameChart = `${titlePrefix} ${timePeriod
    .charAt(0)
    .toUpperCase()}${timePeriod.slice(1)}`

  const filterKpiData = useCallback(
    (data) => data?.map((entry) => entry.result),
    [],
  )

  const kpiOptions = useCallback(
    (data) =>
      chartOptions(
        data?.map((entry, index) => formatTimePeriod(entry, index, timePeriod)),
      ),
    [formatTimePeriod, timePeriod],
  )

  return (
    <LineChartReport
      name={name}
      title={title || `${nameChart} - ${country}`}
      subtitle={subtitle}
      linesConfig={[{name: title || nameChart, filterData: filterKpiData}]}
      params={params}
      chartOptions={kpiOptions}
      defaultStartDate={defaultStartDate}
      defaultEndDate={defaultEndDate}
      showReportsFilter={showReportsFilter}
    />
  )
}

KpiReport.defaultProps = {
  subtitle: undefined,
  title: undefined,
  defaultStartDate: '',
  defaultEndDate: '',
  showReportsFilter: false,
}

KpiReport.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  titlePrefix: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  defaultStartDate: PropTypes.string,
  defaultEndDate: PropTypes.string,
  showReportsFilter: PropTypes.bool,
}

export default KpiReport

import React, {createContext, useContext, useState} from 'react'

const KpiContext = createContext()

export const KpiContextProvider = ({children}) => {
  const [timePeriod, setTimePeriod] = useState('month')
  const [country, setCountry] = useState('US')

  return (
    <KpiContext.Provider
      value={{timePeriod, setTimePeriod, country, setCountry}}
    >
      {children}
    </KpiContext.Provider>
  )
}

export const useKpiContext = () => {
  return useContext(KpiContext)
}

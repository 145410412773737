import React from 'react'
import {SWRConfig} from 'swr'

import {fetcher} from '../../api/fetcher'

// https://swr.vercel.app/docs/global-configuration
const withSWR = (Component) => (props) => {
  return (
    <SWRConfig
      value={{
        // Interval at 35s
        // refreshInterval: 35000,
        fetcher,
        revalidateOnFocus: false,
        //url#cache-provider
        provider: () => new Map(),
      }}
    >
      <Component {...props} />
    </SWRConfig>
  )
}

export default withSWR

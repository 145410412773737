import React from 'react'

// Chakra imports
import {
  Avatar,
  Flex,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'

import {useRailsContext} from '../../../contexts/RailsContext'

// Custom components
import Card from '../../../components/card/Card'

export default function Profile() {
  const {organisation} = useRailsContext()
  // eslint-disable-next-line
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')

  return (
    <Card mb='20px' alignItems='center'>
      <Flex
        w='100%'
        bgGradient='linear(to-b, brand.400, brand.600)'
        minH='127px'
        borderRadius='16px'
      ></Flex>
      <Avatar mx='auto' h='87px' w='87px' mt='-43px' mb='15px' />
      <Text fontSize='2xl' textColor={textColorPrimary} fontWeight='700'>
        {organisation.name}
      </Text>
    </Card>
  )
}

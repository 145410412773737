import APINetworkError from './APINetworkError'

const fetcher = async (...args) => {
  try {
    const res = await fetch(...args)

    const result = await res.json()

    if (res.status >= 400) {
      const err = new Error(
        result.error ? result.error.message : res.statusText,
      )
      err.status = res.status
      throw err
    }
    return result
  } catch (e) {
    // This would normally throw TypeError which mostly relates to
    // - CORS (less likely to occur)
    // - Network request abortion during page transition (big possibility when using SWR)
    // Hence, adding custom error implementation for distinct error handling
    throw new APINetworkError(e.message)
  }
}

const defaultHeaders = (authenticityToken) => {
  return {
    'Content-Type': 'application/json',
    'X-CSRF-Token': authenticityToken,
  }
}

// == POST/PATCH actions here

// import {decamelizeKeys} from 'humps'
//
//
// export const createSomething = ({deal, authenticityToken}) => {
//   const options = {
//     method: 'POST',
//     headers: defaultHeaders = ({authenticityToken}),
//     body: JSON.stringify({something: decamelizeKeys(something)})
//   }
//
//   return fetcher('/api/something/', options)
// }

export {
  fetcher,
  defaultHeaders,
  // postHeaders,
  // getHeaders
}

import React from 'react'

import PropTypes from 'prop-types'

// Chakra imports
import {
  Menu as ChakraMenu,
  MenuButton,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react'

const Menu = ({closeOnSelect, children, title, ...props}) => {
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100')
  const bgHover = useColorModeValue(
    {bg: 'secondaryGray.400'},
    {bg: 'whiteAlpha.50'},
  )
  const bgFocus = useColorModeValue(
    {bg: 'secondaryGray.300'},
    {bg: 'whiteAlpha.100'},
  )

  // Ellipsis modals
  const {isOpen, onOpen, onClose} = useDisclosure()

  return (
    <ChakraMenu isOpen={isOpen} onClose={onClose} closeOnSelect={closeOnSelect}>
      <MenuButton
        data-testid="menu"
        align="center"
        justifyContent="center"
        bg={bgButton}
        _hover={bgHover}
        _focus={bgFocus}
        _active={bgFocus}
        ml={{base: '5px', md: '10px'}}
        lineHeight="100%"
        onClick={onOpen}
        borderRadius="10px"
        minH="40px"
        {...props}
      >
        {title}
      </MenuButton>
      {children}
    </ChakraMenu>
  )
}

Menu.defaultProps = {
  title: undefined,
  closeOnSelect: true,
  onChange: () => {},
}

Menu.propTypes = {
  title: PropTypes.string,
  closeOnSelect: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default Menu

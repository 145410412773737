import React from 'react'

import PropTypes from 'prop-types'

// Custom components
import TableReport from '../../../components/reports/TableReport'

const UpcomingAutoships = ({name}) => {
  const upcomingAutoshipsDataColumns = [
    {
      header: 'Name',
      accessor: 'name',
    },
    {
      header: 'Next Run Date',
      accessor: 'next_run_date',
      is_date: true,
    },
    {
      header: 'Description',
      accessor: 'item_description',
    },
    {
      header: 'Qty',
      accessor: 'quantity',
      is_int: true,
    },
  ]

  return <TableReport name={name} dataColumns={upcomingAutoshipsDataColumns} />
}

UpcomingAutoships.propTypes = {
  name: PropTypes.string.isRequired,
}

export default UpcomingAutoships

import React, {useCallback, useState} from 'react'
import PropTypes from 'prop-types'

// Custom components
import {chartOptions} from '../../../variables/charts'
import LineChartReport from '../../../components/reports/LineChartReport'

const EnrollmentsTrend = ({name}) => {
  const [linesConfig, setLinesConfig] = useState([])

  const uniqueDates = (data) => {
    return data.reduce(
      (acc, curr) =>
        acc.includes(`${curr.month}/${curr.year}`)
          ? acc
          : [...acc, `${curr.month}/${curr.year}`],
      [],
    )
  }

  const filterCustomerType = useCallback(
    (customerType) => (data) => {
      const options = uniqueDates(data)

      return options.map((option) => {
        let row = data.find(
          (element) =>
            option == `${element.month}/${element.year}` &&
            element.customer_type_description == customerType,
        )
        return row?.customer_count || 0
      })
    },
    [],
  )

  const enrollmentsTrendOptions = useCallback(
    (data) => chartOptions(uniqueDates(data)),
    [],
  )

  const handleLinesConfig = useCallback(
    (data) => {
      const customerTypes = data.reduce(
        (acc, curr) =>
          acc.includes(curr.customer_type_description)
            ? acc
            : [...acc, curr.customer_type_description],
        [],
      )
      setLinesConfig(
        customerTypes.map((element) => ({
          name: element,
          filterData: filterCustomerType(element),
        })),
      )
    },
    [filterCustomerType],
  )

  return (
    <LineChartReport
      name={name}
      chartOptions={enrollmentsTrendOptions}
      linesConfig={linesConfig}
      dataCallback={handleLinesConfig}
    />
  )
}

EnrollmentsTrend.propTypes = {
  name: PropTypes.string.isRequired,
}

export default EnrollmentsTrend

import React from 'react'
import PropTypes from 'prop-types'

import {Button} from '@chakra-ui/react'
import {Menu, MenuList} from '../menu'
import {ChevronDownIcon} from '@chakra-ui/icons'

const MultiSelect = ({list, title, isDisabled, onChange, ...props}) => (
  <Menu
    closeOnSelect={false}
    data-testid="selectButton"
    isDisabled={isDisabled}
    as={Button}
    rightIcon={<ChevronDownIcon />}
    title={title}
    {...props}
  >
    <MenuList
      containerProps={{maxH: '200px'}}
      checkboxes
      onChange={onChange}
      options={list}
    />
  </Menu>
)

MultiSelect.defaultProps = {
  title: 'Select options',
  isDisabled: false,
  onChange: () => {},
}

MultiSelect.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  title: PropTypes.string,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
}

export default MultiSelect

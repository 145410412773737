import React, {useCallback, useMemo, useState} from 'react'
import PropTypes from 'prop-types'

// Custom components
import {formatWithTime} from '../../utils/formatter'
import ColumnsTable from '../charts/ColumnsTable'
import ReportLayout from './ReportLayout'

const TableReport = ({dataColumns, ...props}) => {
  const [data, setData] = useState([])

  const handleData = useCallback((data) => setData(data), [])

  let tableData = useMemo(
    () =>
      data.map((entry) =>
        dataColumns.reduce((acc, curr) => {
          const value = entry[`${curr.accessor}`]
          return {
            ...acc,
            [curr.accessor]: curr.is_date
              ? formatWithTime(value)
              : curr.is_int
              ? parseInt(value)
              : value,
          }
        }, {}),
      ),
    [data, dataColumns],
  )

  return (
    <ReportLayout handleData={handleData} {...props}>
      <ColumnsTable columnsData={dataColumns} tableData={tableData} />
    </ReportLayout>
  )
}

TableReport.propTypes = {
  dataColumns: PropTypes.array.isRequired,
}

export default TableReport

import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'
import {Button} from '@chakra-ui/react'
import {Menu, MenuList} from '../menu'
import {ChevronDownIcon} from '@chakra-ui/icons'
import PeriodOption from './PeriodOption'

const PeriodSelector = ({
  reportName,
  selectedPeriod,
  handleOnChange,
  isDisabled,
}) => {
  const {data, error, isLoading} = useSWR(`/api/reports/${reportName}/periods`)

  const periodsList = useMemo(() => {
    if (!!data && data?.length) {
      return data.map((period) => ({
        key: period.id,
        name: period.description,
        type: period.type_id,
      }))
    }
    return []
  }, [data])

  return (
    <Menu
      isDisabled={isLoading || error || isDisabled}
      as={Button}
      rightIcon={<ChevronDownIcon />}
      title={selectedPeriod || 'Select period'}
    >
      <MenuList
        containerProps={{maxH: '200px', overflow: 'scroll'}}
        options={periodsList}
        renderOption={PeriodOption}
        onChange={handleOnChange}
      />
    </Menu>
  )
}

PeriodSelector.defaultProps = {
  selectedPeriod: undefined,
  handleOnChange: () => {},
  isDisabled: false,
}

PeriodSelector.propTypes = {
  reportName: PropTypes.string.isRequired,
  selectedPeriod: PropTypes.string,
  handleOnChange: PropTypes.func,
  isDisabled: PropTypes.bool,
}

export default PeriodSelector

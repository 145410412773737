import React, {useCallback} from 'react'
import PropTypes from 'prop-types'

// Custom components
import {
  formatDate,
  beginningOfMonth,
  endOfMonth,
} from '../../../utils/formatter'
import BarChartReport from '../../../components/reports/BarChartReport'

const MonthlyEnrollmentsByType = ({name}) => {
  const formatedBeginningOfMonth = formatDate(beginningOfMonth())
  const formatedEndOfMonth = formatDate(endOfMonth())

  const monthlyEnrollmentsByTypeChartData = useCallback(
    (data) => data?.map((entry) => entry.customer_count),
    [],
  )

  const monthlyEnrollmentsByTypeChartOptions = useCallback(
    (data) => data?.map((entry) => `${entry.customer_type_description}`),
    [],
  )

  return (
    <BarChartReport
      name={name}
      filterData={monthlyEnrollmentsByTypeChartData}
      filterOptions={monthlyEnrollmentsByTypeChartOptions}
      columnsWidth={'20%'}
      defaultStartDate={formatedBeginningOfMonth}
      defaultEndDate={formatedEndOfMonth}
      showReportsFilter
    />
  )
}

MonthlyEnrollmentsByType.propTypes = {
  name: PropTypes.string.isRequired,
}

export default MonthlyEnrollmentsByType

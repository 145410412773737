import React from 'react'
import PropTypes from 'prop-types'
import {Flex, Text, Icon, Box} from '@chakra-ui/react'
import {WarningTwoIcon} from '@chakra-ui/icons'

const ErrorMessage = ({error, children}) => {
  return !!error && error?.name ? (
    <Flex
      data-testid="errorMessage"
      minH="50%"
      alignItems="center"
      justifyContent="center"
      flexDirection="row"
    >
      <Icon as={WarningTwoIcon} width="15px" height="15px" mr="5px" />
      <Text>Something went wrong</Text>
    </Flex>
  ) : (
    <Box minH="260px" w="100%" justifyContent="flex-start">
      {children}
    </Box>
  )
}

ErrorMessage.defaultProps = {
  error: undefined,
}

ErrorMessage.propTypes = {
  error: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  children: PropTypes.node.isRequired,
}

export default ErrorMessage

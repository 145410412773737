import React from 'react'

import PropTypes from 'prop-types'

// Chakra imports
import {Icon, useColorModeValue, IconButton} from '@chakra-ui/react'
// Assets
import {MdOutlineMoreHoriz} from 'react-icons/md'
import {SiMicrosoftexcel} from 'react-icons/si'
import {PiFileCsvDuotone} from 'react-icons/pi'
// Custom components
import {Menu, MenuList} from '../menu'
import OptionMenu from './OptionMenu'

export default function ExportMenu({reportId, params, ...props}) {
  const iconColor = useColorModeValue('brand.500', 'white')

  return (
    <Menu
      data-testid="exportMenu"
      w="30px"
      h="30px"
      as={IconButton}
      icon={
        <Icon as={MdOutlineMoreHoriz} color={iconColor} w="24px" h="24px" />
      }
      {...props}
    >
      <MenuList
        containerProps={{
          minW: 'unset',
        }}
        optionsProps={{
          mb: '10px',
        }}
        options={[
          {
            'data-testid': 'exportXlsx',
            href: `/api/reports/${reportId}/export_xlsx${params}`,
            icon: SiMicrosoftexcel,
            name: 'Export as xlsx',
          },
          {
            'data-testid': 'exportCsv',
            href: `/api/reports/${reportId}/export_csv${params}`,
            icon: PiFileCsvDuotone,
            name: 'Export as csv',
          },
        ]}
        renderOption={OptionMenu}
      />
    </Menu>
  )
}

ExportMenu.defaultProps = {
  params: '',
}

ExportMenu.propTypes = {
  reportId: PropTypes.string.isRequired,
  params: PropTypes.string,
}

import React from 'react'
import PropTypes from 'prop-types'

import {Flex, useColorModeValue, Button, Text} from '@chakra-ui/react'

const FilterDate = ({header, date, handleDateChange, key}) => {
  const textColorSecondary = useColorModeValue('secondaryGray.600', 'white')
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100')

  return (
    <Flex flexDir={'column'} px="2px">
      <Text>{header}</Text>
      <Button
        bg={boxBg}
        fontSize={{base: 'xs', xl: 'sm'}}
        fontWeight="500"
        color={textColorSecondary}
        borderRadius="7px"
      >
        <input
          data-testid={key}
          style={{background: '#F4F7FE'}}
          name={key}
          type="date"
          value={date}
          onChange={handleDateChange}
        />
      </Button>
    </Flex>
  )
}

FilterDate.defaultProps = {
  header: '',
}

FilterDate.propTypes = {
  header: PropTypes.string,
  date: PropTypes.string.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  key: PropTypes.string.isRequired,
}

export default FilterDate

import React from 'react'
import PropTypes from 'prop-types'
import {Text} from '@chakra-ui/react'

const PeriodOption = (option) => (
  <Text w="100%" pb="10px">
    {option?.name}
  </Text>
)

PeriodOption.propTypes = {
  option: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
}

export default PeriodOption

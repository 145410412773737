import React from 'react'

import PropTypes from 'prop-types'

// Custom components
import KpiReport from '../../../components/reports/KpiReport'

const OrdersByTimeFrame = ({title, name, ...props}) => {
  const titlePrefix = 'Orders By'

  const showReportsFilter = window.location.pathname != '/kpi'

  return (
    <KpiReport
      title={title}
      name={name}
      titlePrefix={titlePrefix}
      showReportsFilter={showReportsFilter}
      {...props}
    />
  )
}

OrdersByTimeFrame.defaultProps = {
  title: undefined,
  name: undefined,
}

OrdersByTimeFrame.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
}
export default OrdersByTimeFrame

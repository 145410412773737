import React from 'react'

import {ChakraProvider} from '@chakra-ui/react'
import '../../assets/css/App.css'
import theme from '../../theme/theme'

const ThemeProvider = ({children}) => (
  <ChakraProvider theme={theme}>{children}</ChakraProvider>
)

export default ThemeProvider

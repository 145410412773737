import React from 'react'

// Chakra imports
import {
  Flex,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'

export default function InputField(props) {
  const { id, label, extra, placeholder, type, mb, value, ...rest } = props
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')

  return (
    <Flex direction='column' mb={mb ? mb : '30px'}>
      <FormLabel
        display='flex'
        ms='10px'
        htmlFor={id}
        fontSize='sm'
        color={textColorPrimary}
        fontWeight='bold'
        _hover={{ cursor: 'pointer' }}>
        {label}
        <Text fontSize='sm' fontWeight='400' ms='2px'>
          {extra}
        </Text>
      </FormLabel>
      <Input
        {...rest}
        type={type}
        id={id}
        disabled
        _disabled={{color: 'secondaryGray.800'}}
        fontWeight='500'
        variant='main'
        placeholder={placeholder}
        value={value}
        _placeholder={{fontWeight: '400', color: 'secondaryGray.600'}}
        h='44px'
        maxh='44px'
      />
    </Flex>
  )
}

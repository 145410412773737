export const formatWithTime = (date) => {
  const newDate = new Date(date)

  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }

  const formatedDate = newDate.toLocaleDateString('en-US', options)

  return formatedDate
}

export const formatDate = (date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // The month goes from 0 to 11, that's why we add 1
  const day = String(date.getDate()).padStart(2, '0')

  const formatedDate = `${year}-${month}-${day}`

  return formatedDate
}

export const beginningOfYear = () => {
  const date = new Date()
  const year = date.getFullYear()

  return new Date(year, 0, 1)
}

export const endOfYear = () => {
  const date = new Date()
  const year = date.getFullYear()

  return new Date(year, 11, 31)
}

export const beginningOfMonth = (_date) => {
  const date = _date || new Date()
  const year = date.getFullYear()
  const month = date.getMonth()

  return new Date(year, month, 1)
}

export const endOfMonth = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth()

  return new Date(year, month + 1, 0)
}

export const oneYearAgo = () => {
  const date = new Date()
  date.setFullYear(date.getFullYear() - 1)
  return date
}

export const monthToName = (monthNumber) => {
  const monthName = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  return monthName[monthNumber - 1]
}

export const formatTimePeriod = (entry, index, timePeriod) => {
  switch (timePeriod) {
    case 'day':
      return index % 3 === 0 ? entry.date : ''
    case 'week':
      return `Week ${entry.week}, ${entry.year}`
    case 'month':
      return `${monthToName(entry.month)}/${entry.year}`
    case 'year':
      return entry.year
    case 'quarterly':
      return `Q${entry.quarterly}, ${entry.year}`
    default:
      return ''
  }
}

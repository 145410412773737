import React from 'react'

import PropTypes from 'prop-types'

// Custom components
import KpiReport from '../../../components/reports/KpiReport'

const SalesByTimeFrame = ({title, name, ...props}) => {
  const titlePrefix = 'Sales By'

  const showReportsFilter = window.location.pathname != '/kpi'

  return (
    <KpiReport
      title={title}
      name={name}
      titlePrefix={titlePrefix}
      showReportsFilter={showReportsFilter}
      {...props}
    />
  )
}

SalesByTimeFrame.defaultProps = {
  title: undefined,
  name: undefined,
}

SalesByTimeFrame.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
}

export default SalesByTimeFrame

import React from 'react'

import {Flex, Link, List, ListItem, useColorModeValue} from '@chakra-ui/react'

export default function Footer() {
  const textColor = useColorModeValue('gray.400', 'white')

  return (
    <Flex
      zIndex="3"
      flexDirection={{
        base: 'column',
        xl: 'row',
      }}
      alignItems={{
        base: 'center',
        xl: 'start',
      }}
      justifyContent="space-between"
      px={{base: '30px', md: '50px'}}
      pb="30px"
    >
      <List display="flex">
        <ListItem
          me={{
            base: '20px',
            md: '44px',
          }}
        >
          <Link fontWeight="500" color={textColor} href="/">
            Support
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: '20px',
            md: '44px',
          }}
        >
          <Link fontWeight="500" color={textColor} href="/">
            License
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: '20px',
            md: '44px',
          }}
        >
          <Link fontWeight="500" color={textColor} href="/">
            Terms of Use
          </Link>
        </ListItem>
      </List>
    </Flex>
  )
}

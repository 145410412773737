import React from 'react'

// Chakra imports
import {
  Flex,
  FormControl,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'

import {useRailsContext} from '../../../contexts/RailsContext'

// Custom components
import Card from '../../../components/card/Card'
import InputField from '../../../components/fields/InputField'

export default function Info() {
  const {organisation} = useRailsContext()
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white')
  const textColorSecondary = 'secondaryGray.600'

  return (
    <FormControl>
      <Card>
        <Flex direction='column' mb='40px' ms='10px'>
          <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
          Organisation Profile
          </Text>
          <Text fontSize='md' color={textColorSecondary}>
            Organisation Information
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ sm: 1, md: 2 }}
          spacing={{ base: '20px', xl: '20px' }}
        >
          <InputField
            mb='25px'
            me='30px'
            id='name'
            label='Name'
            placeholder='Name'
            value={`${organisation.name}`}
          />
          <InputField
            mb='25px'
            id='email'
            label='Email Address'
            placeholder='Email Address'
            value={`${organisation.email}`}
          />
        </SimpleGrid>
      </Card>
    </FormControl>
  )
}

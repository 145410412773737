import React, {useState, useCallback} from 'react'

import PropTypes from 'prop-types'

// Custom components
import LineChartReport from '../../../components/reports/LineChartReport'
import {chartOptions} from '../../../variables/charts'
import {formatDate, oneYearAgo} from '../../../utils/formatter'

const TotalSalesTrend = ({name}) => {
  const defaultStartDate = formatDate(oneYearAgo())
  const defaultEndDate = formatDate(new Date())

  const [itemDescriptionsToShow, setItemDescriptionsToShow] = useState([])

  const filterTotalSalesTrendOptions = useCallback(
    (data) => {
      const uniqueDates = data?.reduce(
        (acc, curr) =>
          acc.includes(`${curr.month}/${curr.year}`)
            ? acc
            : [...acc, `${curr.month}/${curr.year}`],
        [],
      )
      const sortedUniqueDates = uniqueDates?.sort(
        (a, b) =>
          new Date(a.replace('/', '/1/')) - new Date(b.replace('/', '/1/')),
      )
      return chartOptions(sortedUniqueDates)
    },
    [chartOptions],
  )

  const onChange = useCallback((itemDescriptions) => {
    setItemDescriptionsToShow(
      itemDescriptions?.map((itemDescription) => ({
        name: itemDescription?.name,
        filterData: (data) =>
          data
            ?.filter((entry) => entry.item_description === itemDescription?.key)
            ?.map((element) => element.total),
      })),
    )
  }, [])

  return (
    <LineChartReport
      name={name}
      linesConfig={itemDescriptionsToShow}
      chartOptions={filterTotalSalesTrendOptions}
      showReportsFilter
      showItemsDescriptionSelect
      onSelectItemsDescriptionsChange={onChange}
      defaultStartDate={defaultStartDate}
      defaultEndDate={defaultEndDate}
    />
  )
}

TotalSalesTrend.defaultProps = {
  showReportsFilter: false,
}

TotalSalesTrend.propTypes = {
  name: PropTypes.string.isRequired,
  showReportsFilter: PropTypes.bool,
}

export default TotalSalesTrend

import React, {useCallback} from 'react'
import PropTypes from 'prop-types'

// Custom components
import LineChartReport from '../../../components/reports/LineChartReport'
import {chartOptions} from '../../../variables/charts'

const MTDEnrollmentsVsPriorEnrollments = ({name}) => {
  const filterDataCurrentEnrollments = useCallback(
    (data) => data.map((entry) => entry.current_customer_count),
    [],
  )

  const filterDataPriorEnrollments = useCallback(
    (data) => data.map((entry) => entry.prior_customer_count),
    [],
  )

  const filterOptions = useCallback(
    (data) => chartOptions(data?.map((entry) => `${entry.created_day}`)),
    [],
  )

  return (
    <LineChartReport
      name={name}
      title={'MTD Enrollments vs Prior MTD Enrollments'}
      chartOptions={filterOptions}
      linesConfig={[
        {
          name: 'MTD current enrrollments',
          filterData: filterDataCurrentEnrollments,
        },
        {
          name: 'MTD prior enrrollments',
          filterData: filterDataPriorEnrollments,
        },
      ]}
    />
  )
}

MTDEnrollmentsVsPriorEnrollments.propTypes = {
  name: PropTypes.string.isRequired,
}

export default MTDEnrollmentsVsPriorEnrollments
